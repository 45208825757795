import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppService } from '../app.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  ExternalLinksList: any[];
  DeadlinesList: any[];
  isNotifyExpanded = false;
  gridData: any[];
  flowSource: SafeResourceUrl;
  constructor(private appService: AppService, http: HttpClient, @Inject('BASE_URL') baseUrl: string, public sanitizer: DomSanitizer) {
    this.loadScripts();
    this.GetRightsList();
    this.getallmasterdata();
    this.flowSource = this.sanitizer.bypassSecurityTrustResourceUrl('https://report.omegaassure.com/superset/dashboard/29/?standalone=true');
  }
  getallmasterdata() {
    this.appService.GetAllExternalLinks("")
      .subscribe((data) => {
        this.ExternalLinksList = data;
      }, error => console.log(error))
    this.appService.GetAllDeadlines("")
      .subscribe((data) => {
        this.DeadlinesList = data;
      }, error => console.log(error))
    //this.appService.GetAllStrategyOutstanding()
    //  .subscribe((data) => {
    //    this.gridData = data;
    //  }, error => console.log(error))
  }
  loadScripts() {
    const dynamicScripts = [
      '../../../assets/powerbi/powerbiclient.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
  openNotification() {
    this.isNotifyExpanded = !this.isNotifyExpanded;
  }
  IsWrite?: boolean;
  GetRightsList() {
    this.IsWrite = false;
    this.appService.getloggedusername()
      .subscribe((data) => {
        var userId = data;
       
      }, error => console.log(error))
  };
}
