import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { DataTableDirective } from "angular-datatables";
import { NgbModal, ModalDismissReasons, NgbCalendar, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Http } from '@angular/http';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  @ViewChild("multiSelect", { static: false }) multiSelect;
  IsExpanded = false;
  showAddwindow = false;
  selectedDepartment: any;
  selectedBusinessLine: any;
  IsAddDisabled = false;
  myAppUrl: string = "";
  closeResult = '';
  public settings = {};
  public optionsDepartment = [];
  public optionsBusinessLine = [];
  public optionsTeam = [];
  RegulatoryTaxonomy = {};
  ApprovalMessage = '';
  searchTextGrid: string;
  LogListdata: any;
  gridData: any[];
  user: any;
  userName: string;
  Roles: any;
  DepartmentList: any;
  BusinessLineList: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  timerSubscription: Subscription;
    TeamList: any;
  constructor(private appService: AppService, private modalService: NgbModal, private _http: Http, private toastr: ToastrService,
    private ngbCalendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string>) { }
  ngOnInit() {
    this.myAppUrl = this.appService.getApiURL();
    this.GetRightsList();
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      searching: false,

      columns: [null, null, null, null, { orderable: false }]
    };
    this.settings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      enableCheckAll: true,
      selectAllText: "Select All",
      unSelectAllText: "Unselect All",
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: "search",
      noDataAvailablePlaceholderText: "No Data Available",
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
      placement: "top-left",
      direction: "top",
    };
    this.getallgriddata();
    //this.getallmasterdata();
    //this.subscribeToData();
  }
  getallgriddata() {
    this.appService.getUsers()
      .subscribe((data) => {
        this.gridData = data;
      }, error => console.log(error))
    this.appService.getAllDepartment("")
      .subscribe((data) => {
        this.DepartmentList = data;
        this.bindDepartmentdata(this.DepartmentList);
      }, error => console.log(error))
    this.appService.getAllBusinessLine("")
      .subscribe((data) => {
        this.BusinessLineList = data;
        this.bindBusinessLinedata(this.BusinessLineList);
      }, error => console.log(error))
    this.appService.getAllTeam("")
      .subscribe((data) => {
        this.TeamList = data;
        this.bindTeamdata(this.TeamList);
      }, error => console.log(error))
  }
  order: string = '';
  reverse: boolean = true;
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  ExpandPane() {
    this.IsExpanded = !this.IsExpanded;
  }

  removeLastComma(str) {
    return str.replace(/,(\s+)?$/, '');
  }

  formatDate(data) {
    if (data != undefined && data != '' && data != null) {
      var tcdate = new Date(Date.parse(data));
      return this.getFormattedDateTime(tcdate);
    }
    else {
      return '';
    }
  }

  getFormattedDateTime(d) {
    var now = d;
    var year = now.getFullYear();
    var month = (now.getMonth() + 1).toString();
    var day = (now.getDate()).toString();
    var hour = (now.getHours()).toString();
    var minute = (now.getMinutes()).toString();
    var second = (now.getSeconds()).toString();
    if (month.toString().length == 1) {
      month = '0' + month;
    }
    if (day.toString().length == 1) {
      day = '0' + day;
    }
    if (hour.toString().length == 1) {
      hour = '0' + hour;
    }
    if (minute.toString().length == 1) {
      minute = '0' + minute;
    }
    if (second.toString().length == 1) {
      second = '0' + second;
    }
    var dateTime = day + '/' + month + '/' + year + ' ' + hour + ':' + minute;
    return dateTime;
  }

  GetViewLog(Id, content) {
    this.appService.getAllCommonVersionLog(Id, 'User', 'Log', '')
      .subscribe((data) => {
        this.LogListdata = data;
        for (var i = 0; i < this.LogListdata.length; i++) {
          this.LogListdata[i].Version = i + 1;
        }
      }, error => console.log(error))
    this.openXl(content);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  showtempadd(content) {
    //this.InvalidMessage = '';
    this.user = { RoleId:''};
    this.openXl(content)
    this.bindDepartmentdata(this.DepartmentList);
    this.bindBusinessLinedata(this.BusinessLineList);
    this.bindTeamdata(this.TeamList);
    this.IsAddDisabled = false;
  };
  GetUser(userId, content) {
    this.appService.getUser(userId)
      .subscribe((data) => {
        this.user = data[0];
        //this.appService.getAllTeamMappingbyId(userId)
        //  .subscribe((data) => {
        //    this.user.Team = data.map(function (val) {
        //      return val.Team;
        //    }).join(',');
        //  }, error => console.log(error))
        this.user.BusinessLine = ''; this.user.Department = '';
        this.appService.GetBusinessMapping(userId)
          .subscribe((data) => {
            this.user.BusinessLine = data.map(function (val) {
              return val.BusinessLine;
            }).join(',');
          }, error => console.log(error))
        this.appService.GetuserDepartmentMapping(userId)
          .subscribe((data) => {
            this.user.Department = data.map(function (val) {
              return val.Department;
            }).join(',');
          }, error => console.log(error))
        this.appService.GetUserTeamMapping(userId)
          .subscribe((tdata) => {
            this.user.TeamMapping = tdata.map(function (val) {
              return val.Team;
            }).join(',');
          }, error => console.log(error))
        this.openXl(content)
      }, error => console.log(error))
  };
  ResetPasswordReq() {
    var input = { userId: this.user.userId, Id: this.user.Id }
    this._http.post(this.myAppUrl + 'ResetPasswordReq', input).subscribe(
      data => {        
        if (data["_body"] == "success") {
          this.toastr.success('"Password Reset" request has been sent successfully', "");
          this.modalService.dismissAll();
        }
        else {
          this.toastr.warning(data.json(), "");;
        }
      },
      error => {
        console.log(JSON.stringify(error.json()));
      }
    )
  }
  CreateTempUser(user) {
    //this.InvalidMessage = '';
    if (user.userId == undefined || user.userId == null || user.userId == '') {
      this.toastr.warning('Please enter "User ID"', "");
    }
    else if (user.UserName == undefined || user.UserName == null || user.UserName == '') {
      this.toastr.warning('Please enter "User Name"', "");
    }
    else if (user.MobileNumber == undefined || user.MobileNumber == null || user.MobileNumber == '') {
      this.toastr.warning('Please enter "Mobile Number"', "");
    }
    else if (user.emailId == undefined || user.emailId == null || user.emailId == '') {
      this.toastr.warning('Please enter "Email ID"', "");
    }
    else if (!this.IsValidEmail(user.emailId)) {
      this.toastr.warning('Please enter valid "Email ID"', "");
    }
    else {
      this.IsAddDisabled = true;
      this.appService.getUser(user.userId)
        .subscribe((data) => {
          if (data.length > 0) {
            //this.userId = '';
            //this.InvalidMessage = 'The UserID already exists!';
            this.toastr.warning('"User ID" already exists', "");;
            this.IsAddDisabled = false;
          }
          else {
            var DepartmentList = [];
            var BusinessLineList = [];
            //console.log(this.selectedDepartment)
            if (this.selectedDepartment != undefined && this.selectedDepartment != null) {
              for (var i = 0; i < this.selectedDepartment.length; i++) {
                var lst = { Id: '' };
                if (i == this.selectedDepartment.length - 1) {
                  lst.Id = this.selectedDepartment[i].item_id;
                  DepartmentList.push(lst);
                }
                else {
                  lst.Id = this.selectedDepartment[i].item_id;
                  DepartmentList.push(lst);
                }
              }
            }
            user.DepartmentList = DepartmentList;
            if (this.selectedBusinessLine != undefined && this.selectedBusinessLine != null) {
              for (var i = 0; i < this.selectedBusinessLine.length; i++) {
                var lst = { Id: '' };
                if (i == this.selectedBusinessLine.length - 1) {
                  lst.Id = this.selectedBusinessLine[i].item_id;
                  BusinessLineList.push(lst);
                }
                else {
                  lst.Id = this.selectedBusinessLine[i].item_id;
                  BusinessLineList.push(lst);
                }
              }
            }
            user.BusinessLineList = BusinessLineList;
            user.TeamList = [];
            if (this.multiselectTeam != undefined && this.multiselectTeam != null) {
              for (let ol of this.multiselectTeam) {
                var lstT = { Id: ol.item_id };
                user.TeamList.push(lstT);
              }
            }
            this._http.post(this.myAppUrl + 'CreateTempUser', user).subscribe(
              data => {
                if (data.json() == "success") {
                  this.user = {};
                  this.toastr.success('"User" added successfully', "");
                  this.getallgriddata();
                  this.modalService.dismissAll();
                }
                else {
                  this.toastr.warning(data.json(), "");;
                }
                this.IsAddDisabled = false;
              },
              error => {
                console.log(JSON.stringify(error.json()));
              }
            )
          }
        }, error => console.log(error))
    }
  };
  ViewData: any;
  Prevvalue: any;
  edit(obj, content) {
    this.user = obj;
    this.IsAddDisabled = false;
    //this.bindDepartmentdata(this.DepartmentList);
    //this.appService.getAllTeamMappingbyId(obj.userId)
    //  .subscribe((data) => {
    //    this.assignDepartment(data)
    //  }, error => console.log(error))
    var BusinessLineList = []; var DepartmentList = [];
    this.bindBusinessLinedata(this.BusinessLineList);
    this.appService.GetBusinessMapping(obj.userId)
      .subscribe((data) => {
        this.assignClient(data)
        for (var i = 0; i < this.selectedBusinessLine.length; i++) {
          var obj1 = {
            Id: this.selectedBusinessLine[i].item_id,
            BusinessLine: this.selectedBusinessLine[i].item_text,
          }
          BusinessLineList.push(obj1);
        }
        this.user.BusinessLineList = BusinessLineList;
        this.appService.GetuserDepartmentMapping(obj.userId)
          .subscribe((data) => {
            this.assignDepartment(data)
            for (var i = 0; i < this.selectedDepartment.length; i++) {
              var obj1 = {
                Id: this.selectedDepartment[i].item_id,
                Department: this.selectedDepartment[i].item_text,
              }
              DepartmentList.push(obj1);
            }
            this.user.DepartmentList = DepartmentList;
            this.Prevvalue = Object.assign({}, this.user);
          }, error => console.log(error))
      }, error => console.log(error))
    this.appService.GetUserTeamMapping(obj.userId)
      .subscribe((data) => {
        this.assignTeam(data)
        this.user.TeamList = [];
        for (let ol of this.multiselectTeam) {
          var objT = {
            Id: ol.item_id,
            Team: ol.item_text,
          }
          this.user.TeamList.push(objT);
        }
        this.Prevvalue = Object.assign({}, this.user);

      }, error => console.log(error))
    this.openXl(content)
    
  }
  ModifyUser(data) {
    if (data.MobileNumber == undefined || data.MobileNumber == null || data.MobileNumber == '') {
      this.toastr.warning('Please enter "Mobile Number"', "");
    }
    else {
      this.IsAddDisabled = true;
      var DepartmentList = [];
      for (var i = 0; i < this.selectedDepartment.length; i++) {
        var obj = {
          Id: this.selectedDepartment[i].item_id,
          Department: this.selectedDepartment[i].item_text,
        }
        DepartmentList.push(obj);
      }
      data.DepartmentList = DepartmentList;
      var BusinessLineList = [];
      for (var i = 0; i < this.selectedBusinessLine.length; i++) {
        var arr = {
          Id: this.selectedBusinessLine[i].item_id,
          BusinessLine: this.selectedBusinessLine[i].item_text,
        }
        BusinessLineList.push(arr);
      }
      data.BusinessLineList = BusinessLineList;
      data.TeamList = [];
      if (this.multiselectTeam != undefined && this.multiselectTeam != null) {
        for (let ol of this.multiselectTeam) {
          var objT = {
            Id: ol.item_id,
            Team: ol.item_text,
          }
          data.TeamList.push(objT);
        }
      }
      var array = [];
      array.push(data);
      array.push(this.Prevvalue);
      this._http.post(this.myAppUrl + 'ModifyUser', array).subscribe(
        data => {
          if (data.json() == "success") {
            this.user = {};
            
            this.toastr.success('"User" details updated successfully', "");
            this.getallgriddata();
            this.modalService.dismissAll();
          }
          else {
            this.toastr.warning(data.json(), "");;
          }
          this.IsAddDisabled = false;
        },
        error => {
          console.log(JSON.stringify(error.json()));
        }
      )
    }
  };
  IsValidEmail(obj) {
    const pattern = /^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/;

    if (!obj.match(pattern)) {
      return false;
    }
    else {
      return true;
    }
  }
  multiselectTeam: any[];
  bindTeamdata(list) {
    this.multiselectTeam = [];
    this.optionsTeam = list.map(function (item) {
      return {
        item_text: item.Team,
        item_id: item.Id
      };
    });
  }
  assignTeam(data) {
    this.multiselectTeam = [];
    for (var i = 0; i < data.length; i++) {
      this.multiselectTeam.push({ item_id: data[i].Id, item_text: data[i].Team });
    }
  }
  bindDepartmentdata(list) {
    this.selectedDepartment = [];
    this.optionsDepartment = list.map(function (item) {
      return {
        item_text: item.Department,
        item_id: item.Id
      };
    });
  }
  bindBusinessLinedata(list) {
    this.selectedBusinessLine = [];
    this.optionsBusinessLine = list.map(function (item) {
      return {
        item_text: item.BusinessLine,
        item_id: item.Id
      };
    });
  }
  assignDepartment(data) {
    this.selectedDepartment = [];
    for (var i = 0; i < data.length; i++) {
      this.selectedDepartment.push({ item_id: data[i].Id, item_text: data[i].Department });
    }
    //for (var index = 0; index < data.length; index++) {

    //  for (var k = 0; k < this.optionsDepartment.length; k++) {
    //    if (this.optionsDepartment[k].item_id == data[index].trim()) {
    //      this.selectedDepartment.push(this.optionsDepartment[k]);
    //    }
    //  }
    //}
  }
  assignClient(data) {
    this.selectedBusinessLine = [];
    for (var i = 0; i < data.length; i++) {
      this.selectedBusinessLine.push({ item_id: data[i].Id, item_text: data[i].BusinessLine });
    }
    //this.selectedBusinessLine = [];

    //for (var index = 0; index < data.length; index++) {

    //  for (var k = 0; k < this.optionsBusinessLine.length; k++) {
    //    if (this.optionsBusinessLine[k].item_id == data[index].trim()) {
    //      this.selectedBusinessLine.push(this.optionsBusinessLine[k]);
    //    }
    //  }
    //}
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: 'modal-small' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal' });
  }

  openSm(content) {
    this.modalService.open(content, { size: 'sm' });
  }

  openLg(content) {
    this.modalService.open(content, { size: 'lg' });
  }
  openXl(content) {
    this.modalService.open(content, { size: 'xl' });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  IsWrite?: boolean;
  GetRightsList() {
    this.IsWrite = false;
    this.appService.getloggedusername()
      .subscribe((data) => {
        var userId = data;
      }, error => console.log(error))
  };
}
