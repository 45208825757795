import { Component, Inject, OnInit } from '@angular/core';
import {  Router, ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { SessionModalComponent } from '../session-modal/session-modal.component';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  timeout = 30;
  warningTimer: any;
  closeResult = '';
  //@ViewChild(SessionModalComponent, { static: false }) sessModal: SessionModalComponent;
  constructor(private modalService: NgbModal, private _avRoute: ActivatedRoute, @Inject(Router) private _router: Router) {
    this.init();
  }

  ngOnInit() {
    this.timeout = 30;
    //this.startSessionTimer(this.timeout, 2, '/logout');
    this.loadScripts();
  }
  timeoutSeconds: any; warningSeconds: any; secondsUntilWarning: any; url: any;
  public timeAccessed: any;
  startSessionTimer(sessionMinutes, sessionWarningMinutes, logoutUrl) {
    this.timeoutSeconds = sessionMinutes * 60;
    this.warningSeconds = sessionWarningMinutes * 60;
    this.secondsUntilWarning = this.timeoutSeconds - this.warningSeconds;
    this.url = logoutUrl;
    if (this.timeAccessed != undefined && this.timeAccessed != null && this.timeAccessed != '') {
      this.timeAccessed = new Date(this.timeAccessed);
      var now = new Date();
      this.timeAccessed.setSeconds(this.timeAccessed.getSeconds() + this.timeoutSeconds);
      //if (now > timeAccessed) {
      //    timeOut();
      //}
    }
    this.timeAccessed = new Date();
    localStorage.setItem("timeAccessed", new Date().toString())
    localStorage.setItem("warningSeconds", this.warningSeconds)
    if (this.timeAccessed != undefined && this.timeAccessed != null) {
      localStorage.setItem("secondsUntilWarning", (this.secondsUntilWarning * 1000).toString())
      this.warningTimer = setTimeout(this.openWarning, this.secondsUntilWarning * 1000);
    }
  };
  resetTimer() {
    //this.timeAccessed = new Date();
    localStorage.setItem("timeAccessed", new Date().toString());
    //localStorage.setItem("sessionTimeoutModal", "no");
    clearTimeout(this.warningTimer);
    clearInterval(this.timer);
    $.ajax({
      url: '/Main/KeepAlive', // Redirects to action method for every 20 minutes.
      dataType: "json",
      type: "GET",
    });
    this.modalService.dismissAll();
    this.warningTimer = setTimeout(this.openWarning, parseInt(localStorage.getItem("secondsUntilWarning")) * 1000);
    //$('#sessionTimeoutModal').modal('hide');
  }
  showModal: any;
  openWarning() {
    //var dObj = window.localStorage.getItem("timeAccessed");
    //var dObj1 = new Date(dObj);
    //dObj1.setDate(dObj1.getDate() + 7);
    this.timeAccessed = new Date(Date.parse(localStorage.getItem("timeAccessed")));
    var now = new Date();
    this.timeAccessed.setSeconds(this.timeAccessed.getSeconds() + parseInt(localStorage.getItem("secondsUntilWarning")));
    if (now >= this.timeAccessed) {
      //localStorage.setItem("sessionTimeoutModal", "yes");
      //$('#sessionTimeoutModal').modal('show');
      //console.log(this.modalContent)
      //this.open(this.modalContent);
      document.getElementById("openModalButton").click();
      //this.startCount();
      //sendDecktopNotification();
      var counter = parseInt(localStorage.getItem("warningSeconds"));
      // initialize timer
      //$('#timerInterval').html(counter);
      this.timerInterval = counter;
      // create a timer that runs every second
      //this._router.navigate(['logout']);
      //document.getElementById("seslogout").click();
      this.timer = setInterval(function () {
        counter -= 1
        if (counter > 0) {
          //$('#timerInterval').html(counter);
          this.timerInterval = counter;
          //localStorage.setItem("timerInterval", counter.toString())
          document.getElementById('sestimerInterval').textContent = counter.toString();
          //(document.getElementById('timerInterval') as HTMLImageElement).html = counter;
        }
        else {
          document.getElementById("seslogout").click();
          //this._router.navigate(['logout']);
        }
      }, 1000);
    }
    else {
      localStorage.setItem("timeAccessed", new Date().toString());
      clearTimeout(this.warningTimer);
      clearInterval(this.timer);
      $.ajax({
        url: '/Main/KeepAlive', // Redirects to action method for every 20 minutes.
        dataType: "json",
        type: "GET",
      });
      this.warningTimer = setTimeout(this.openWarning, parseInt(localStorage.getItem("secondsUntilWarning")) * 1000);
      //this.modalService.dismissAll();
    }
  }
  timer: any; timerInterval: any;
  startCount() {
    // initialize timer
    var counter = parseInt(localStorage.getItem("warningSeconds"));
    // initialize timer
    //$('#timerInterval').html(counter);
    this.timerInterval = counter;
    // create a timer that runs every second
    this.timer = setInterval(function () {
      counter -= 1
      if (counter > 0) {
        //$('#timerInterval').html(counter);
        this.timerInterval = counter;
      }
      else {
        this.timeOut();
      }
    }, 1000);
  }
  private init() {
    this.mouseHandlers();
  }
  private mouseHandlers() {
    document.addEventListener('mousemove', this.onMouseMove)
  }
  onMouseMove: { (event: MouseEvent): void } = (event: MouseEvent) => {
    //this.resetTimer();
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openXl(content) {
    this.modalService.open(content, { size: 'xl' });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  loadScripts() {
    const dynamicScripts = [
      '../../../assets/js/popper.min.js',
      '../../../assets/js/bootstrap.min.js',
      '../../../assets/js/SessionTimeout.js',
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
}
