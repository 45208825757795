import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { Observable, of } from 'rxjs';
import { tap, delay, map } from 'rxjs/operators';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isUserLoggedIn: boolean = false;
  logout(): void {
    this.isUserLoggedIn = false;
    localStorage.removeItem('isUserLoggedIn');
  }
  checkUserLoggedIn(): boolean {
    if (localStorage.getItem("isUserLoggedIn") != null) {
      return true;
    }
    return false;
  }
  checkPermission(accessPath): Observable<boolean> {
    return this.appService.getloggedusername()
      .pipe(map(data => {
        if (Boolean(data)) {
          return true;
        }
        else {
          return false;
        }
      }));

  }
  myAppUrl: string = "";
  constructor(private appService: AppService) {
  }
  errorHandler(error: Response) {
    console.log(error);
    return Observable.throw(error);
  }
}
