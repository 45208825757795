import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { AuthenticationGuard } from './authentication.guard';
import { LoginComponent } from './login/login.component';
import { MasterPageComponent } from './master-page/master-page.component';
import { BusinessUnitComponent } from './business-unit/business-unit.component';
import { DepartmentComponent } from './department/department.component';
import { TeamComponent } from './team/team.component';
import { DataSubjectsComponent } from './data-subjects/data-subjects.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { CommentTypeComponent } from './comment-type/comment-type.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { HttpModule } from '@angular/http';
import { DataTablesModule } from 'angular-datatables';
import { NgbAlertModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
//import { FileUploadModule } from 'ng2-file-upload';
import { NgPipesModule } from 'ngx-pipes';
import { OrderModule } from 'ngx-order-pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { WhoseDataComponent } from './whose-data/whose-data.component';
import { UniqDataReferenceComponent } from './uniq-data-reference/uniq-data-reference.component';
import { WhenObtainDataComponent } from './when-obtain-data/when-obtain-data.component';
import { WhatDataComponent } from './what-data/what-data.component';
import { WhyCollectingDataComponent } from './why-collecting-data/why-collecting-data.component';
import { WhereDataStoredComponent } from './where-data-stored/where-data-stored.component';
import { WhatDataCollectComponent } from './what-data-collect/what-data-collect.component';
import { WhoAccessDataComponent } from './who-access-data/who-access-data.component';
import { LogoutComponent } from './logout/logout.component';
import { ConfirmLawfulBasisComponent } from './confirm-lawful-basis/confirm-lawful-basis.component';
import { ReportAComponent } from './report-a/report-a.component';
import { ReportPBIComponent } from './report-pbi/report-pbi.component';
import { MyFilterPipe } from './myfilter.pipe';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    LoginComponent,
    MasterPageComponent,
    UserManagementComponent,
    BusinessUnitComponent,
    DepartmentComponent,
    TeamComponent,
    DataSubjectsComponent,
    ChangePasswordComponent,
    DashboardComponent,
    CommentTypeComponent,
    ConfirmModalComponent,
    WhoseDataComponent,
    UniqDataReferenceComponent,
    WhenObtainDataComponent,
    WhatDataComponent,
    WhyCollectingDataComponent,
    WhereDataStoredComponent,
    WhatDataCollectComponent,
    WhoAccessDataComponent,
    LogoutComponent,
    ConfirmLawfulBasisComponent,
    ReportAComponent,
    ReportPBIComponent,
    MyFilterPipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    HttpModule,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgbAlertModule,
    Ng2SearchPipeModule,
    NgbModule,
    //FileUploadModule,
    NgPipesModule,
    OrderModule,
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
      maxOpened: 1,
      autoDismiss: true,
      enableHtml: true
    }),
    RouterModule.forRoot([
      { path: 'logout', redirectTo: 'logout', pathMatch: 'prefix' },
      { path: '', component: LoginComponent, pathMatch: 'full' },
      { path: 'change-password', component: ChangePasswordComponent, pathMatch: 'full' },
      //{ path: 'two-fact-verification', component: TwoFactVerificationComponent, pathMatch: 'full' },
      {
        path: 'dashboard', component: DashboardComponent,
        children: [
          { path: 'home', component: HomeComponent, canActivate: [AuthenticationGuard] },
          { path: 'login', component: LoginComponent },
          //{ path: 'two-fact-verification', component: TwoFactVerificationComponent },
          { path: 'masterpage', component: MasterPageComponent, canActivate: [AuthenticationGuard] },
          { path: 'usermanagement', component: UserManagementComponent, canActivate: [AuthenticationGuard] },
          //{ path: 'twofactauth', component: TwoFactAuthComponent, canActivate: [AuthenticationGuard] },
          { path: 'CommentType', component: CommentTypeComponent, canActivate: [AuthenticationGuard] },
          { path: 'BusinessUnit', component: BusinessUnitComponent, canActivate: [AuthenticationGuard] },
          { path: 'Department', component: DepartmentComponent, canActivate: [AuthenticationGuard] },
          { path: 'Team', component: TeamComponent, canActivate: [AuthenticationGuard] },
          { path: 'datasubjects', component: DataSubjectsComponent, canActivate: [AuthenticationGuard] },
          { path: 'WhoseData', component: WhoseDataComponent, canActivate: [AuthenticationGuard] },
          { path: 'UniqDataReference', component: UniqDataReferenceComponent, canActivate: [AuthenticationGuard] },
          { path: 'WhenObtainData', component: WhenObtainDataComponent, canActivate: [AuthenticationGuard] },
          { path: 'WhatData', component: WhatDataComponent, canActivate: [AuthenticationGuard] },
          { path: 'WhyCollectingData', component: WhyCollectingDataComponent, canActivate: [AuthenticationGuard] },
          { path: 'WhereDataStored', component: WhereDataStoredComponent, canActivate: [AuthenticationGuard] },
          { path: 'WhatDataCollect', component: WhatDataCollectComponent, canActivate: [AuthenticationGuard] },
          { path: 'WhoAccessData', component: WhoAccessDataComponent, canActivate: [AuthenticationGuard] },
          { path: 'ConfirmLawfulBasis', component: ConfirmLawfulBasisComponent, canActivate: [AuthenticationGuard] },
          { path: 'report-a', component: ReportAComponent, canActivate: [AuthenticationGuard] },
          { path: 'report-pbi', component: ReportPBIComponent, canActivate: [AuthenticationGuard] },
        ]
      }
    ])
  ],
  entryComponents: [ConfirmModalComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
