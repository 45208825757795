import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-report-a',
  templateUrl: './report-a.component.html',
  styleUrls: ['./report-a.component.css']
})
export class ReportAComponent implements OnInit {
  flowSource: SafeResourceUrl;
  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.flowSource = this.sanitizer.bypassSecurityTrustResourceUrl('https://report.omegaassure.com/superset/dashboard/29/?standalone=true');
  }

}
