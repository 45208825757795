import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { NgbModal, ModalDismissReasons, NgbCalendar, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { Http } from '@angular/http';

@Component({
  selector: 'app-what-data-collect',
  templateUrl: './what-data-collect.component.html',
  styleUrls: ['./what-data-collect.component.css']
})
export class WhatDataCollectComponent implements OnInit {
  gridData: any;
  closeResult = '';
  myAppUrl: string = "";
  IsExpanded = false;
  LogListdata: any;
  WhatDataCollectMaster: any;
  searchTextGrid: string;
  @ViewChild(ConfirmModalComponent, { static: false }) child: ConfirmModalComponent;
  constructor(private appService: AppService, private modalService: NgbModal, private _http: Http, private toastr: ToastrService,
    private ngbCalendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string>) { }
  ngOnInit() {
    this.myAppUrl = this.appService.getApiURL();
    this.GetRightsList();
    this.getallgriddata();
    this.appService.events$.forEach(event => {
      if (event == "canceled") {
        this.cancel();
      }
    });
  }
  getallgriddata() {
    this.appService.getAllWhatDataCollect("")
      .subscribe((data) => {
        this.gridData = data;
      }, error => console.log(error))
  }
  ExpandPane() {
    this.IsExpanded = !this.IsExpanded;
  }
  formatDate(data) {
    if (data != undefined && data != '' && data != null) {
      var tcdate = new Date(Date.parse(data));
      return this.getFormattedDateTime(tcdate);
    }
    else {
      return '';
    }
  }
  order: string = '';
  reverse: boolean = true;
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  getFormattedDateTime(d) {
    var now = d;
    var year = now.getFullYear();
    var month = (now.getMonth() + 1).toString();
    var day = (now.getDate()).toString();
    var hour = (now.getHours()).toString();
    var minute = (now.getMinutes()).toString();
    var second = (now.getSeconds()).toString();
    if (month.toString().length == 1) {
      month = '0' + month;
    }
    if (day.toString().length == 1) {
      day = '0' + day;
    }
    if (hour.toString().length == 1) {
      hour = '0' + hour;
    }
    if (minute.toString().length == 1) {
      minute = '0' + minute;
    }
    if (second.toString().length == 1) {
      second = '0' + second;
    }
    var dateTime = day + '/' + month + '/' + year + ' ' + hour + ':' + minute;
    return dateTime;
  }
  editMode = false;
  IsReadOnly = true;
  IsAddDisabled = false;
  showAddwindow = false;
  Showadd(content) {
    this.WhatDataCollectMaster = {};
    this.showAddwindow = true;
    this.IsAddDisabled = false;
    this.editMode = false;
    this.IsExpanded = false;
    this.open(content, 'modal-small');
  }
  GetViewLog(Id, content) {
    this.appService.getAllCommonVersionLog(Id, 'WhatDataCollect', 'Log', '')
      .subscribe((data) => {
        this.LogListdata = data;
        for (var i = 0; i < this.LogListdata.length; i++) {
          this.LogListdata[i].Version = i + 1;
        }
      }, error => console.log(error))
    this.openXl(content);
  }
  add(WhatDataCollectMaster) {
    if (WhatDataCollectMaster.WhatDataCollect == undefined || WhatDataCollectMaster.WhatDataCollect == null || WhatDataCollectMaster.WhatDataCollect == "") {
      this.toastr.warning('Please enter "How Did You Obtain The Data?"', "");;
    }
    else {
      this.IsAddDisabled = true;
      this._http.post(this.myAppUrl + 'AddWhatDataCollect', WhatDataCollectMaster).subscribe(
        data => {
          if (data.json() == "success") {
            this.WhatDataCollectMaster = null;
            this.toastr.success('"How Did You Obtain The Data?" added successfully', "");
            this.getallgriddata();
            this.modalService.dismissAll();
            this.showAddwindow = false;
          }
          else {
            this.toastr.warning(data.json(), "");;
          }
          this.IsAddDisabled = false;
        },
        error => {
          console.log(JSON.stringify(error.json()));
        }
      )
    }

  };
  edit(obj,content) {
    this.WhatDataCollectMaster = {};
    this.WhatDataCollectMaster = Object.assign({}, obj);
    this.editMode = true;
    this.IsAddDisabled = false;
    this.showAddwindow = true;
    this.IsExpanded = false;
    this.Prevvalue = Object.assign({}, obj);
    this.open(content, 'modal-small');
  }
  Prevvalue: any;
  UpdateWhatDataCollectMaster(model) {
    if (model.WhatDataCollect == undefined || model.WhatDataCollect == null || model.WhatDataCollect == "") {
      this.toastr.warning('Please enter "How Did You Obtain The Data?"', "");;
    }
    else {
      var array = [];
      array.push(model);
      array.push(this.Prevvalue);
      this.IsAddDisabled = true;
      this._http.post(this.myAppUrl + 'ModifyWhatDataCollect', array).subscribe(
        data => {
          if (data.json() == "success") {
            this.WhatDataCollectMaster = null;
            this.toastr.success('"How Did You Obtain The Data?" updated successfully', "");
            this.getallgriddata();
            this.modalService.dismissAll();
            this.IsAddDisabled = false;
            this.showAddwindow = false;
          }
          else {
            this.toastr.warning(data.json(), "");;
          }
          this.IsAddDisabled = false;
        },
        error => {
          console.log(JSON.stringify(error.json()));
        }
      )
    }
  };

  confirmModel() {
    const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: 'confirm-modal' });
    modalRef.componentInstance.name = 'Confirm';
  }
  cancel() {
    this.editMode = false;
    this.showAddwindow = false;
    this.modalService.dismissAll();
    this.getallgriddata();
  }
  open(content, className) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: className }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openXl(content) {
    this.modalService.open(content, { size: 'xl' });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  IsWrite?: boolean;
  GetRightsList() {
    this.IsWrite = false;
    this.appService.getloggedusername()
      .subscribe((data) => {
        var userId = data;
        
      }, error => console.log(error))
  };

}
