import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  Router, ActivatedRoute } from '@angular/router';
import { LoginService } from './loginservice.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  loginForm: FormGroup;
  loginErrorMessage = "";
  errorMessage: any;
  constructor(private _fb: FormBuilder,
    private _loginService: LoginService, private _router: Router) {

    this.loginForm = this._fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    })
  }
  login() {

    if (!this.loginForm.valid) {
      return;
    }
    else {
      this._loginService.accountLogin(this.loginForm.value)
        .subscribe((data) => {
          if (data["_body"] == "Logged in successfully") {
            localStorage.setItem('isUserLoggedIn', "true");
            this._router.navigate(['/dashboard/home']);
          }
          else if (data["_body"] == "Reset Password") {
            localStorage.setItem('isUserLoggedIn', "false");
            this._router.navigate(['/change-password']);
          }
          else {
            localStorage.setItem('isUserLoggedIn', "false");
            this.loginErrorMessage = data["_body"];
          }
        }, error => console.log(this.errorMessage = error))
    }
  }

  get username() { return this.loginForm.get('username'); }
  get password() { return this.loginForm.get('password'); }

  //ngOnInit(): void {
  //}

}
interface UserMaster {
  username: string;
  password: string;
}
