import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { DataTableDirective } from "angular-datatables";

@Component({
  selector: 'app-comment-type',
  templateUrl: './comment-type.component.html',
  styleUrls: ['./comment-type.component.css']
})
export class CommentTypeComponent implements OnInit {

  IsExpanded = false;
  showAddwindow = false;
  RegulatoryTaxonomy = {};
  ApprovalMessage = '';
  searchTextGrid: string;
  gridData: any[];
  userName: string;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  timerSubscription: Subscription;
  constructor(private appService: AppService) { }
  ngOnInit() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      searching: false,

      columns: [null, null, null, null, { orderable: false }]
    };
    this.appService.getAllCommentType("")
      .subscribe((data) => {
        this.gridData = data;
      }, error => console.log(error))
    //this.subscribeToData();
  }
  ExpandPane() {
    this.IsExpanded = !this.IsExpanded;
  }
  order: string = '';
  reverse: boolean = true;
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

}
