import { Injectable, Inject } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//@Injectable()
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  myAppUrl: string = "";

  constructor(private _http: Http, @Inject('BASE_URL') baseUrl: string) {
    this.myAppUrl = baseUrl;
  }
  accountLogin(login) {
    return this._http.post(this.myAppUrl + 'Account/Login?userName=' + login.username + '&passWord=' + encodeURIComponent(login.password) + '&auth_type=', login)
      .pipe(map((response: Response) => response))//response.json()
      .catch(this.errorHandler)
  }
  accountChange(login) {
    return this._http.post(this.myAppUrl + 'Account/ChangePassword?newpwd=' + encodeURIComponent(login.newpwd) + '&confirmpwd=' + encodeURIComponent(login.confirmpwd), login)
      .pipe(map((response: Response) => response))//response.json()
      .catch(this.errorHandler)
  }
  account2FAVerification(login) {
    return this._http.post(this.myAppUrl + 'Account/Verify2FA?VerificationCode=' + login.VerificationCode, login)
      .pipe(map((response: Response) => response))//response.json()
      .catch(this.errorHandler)
  }

  errorHandler(error: Response) {
    console.log(error);
    return Observable.throw(error);
  }
}
