import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../app.service';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})

export class ConfirmModalComponent {

  //constructor() { }

  //ngOnInit() {
  //}
  @Input() name;

  constructor(public activeModal: NgbActiveModal, private service: AppService) { }
  cancel() {
    this.service.clickEvent('canceled');
  }

}
