import { PipeTransform, Pipe, Injectable } from '@angular/core';

@Pipe({
  name: 'myfilter'
})
@Injectable()
export class MyFilterPipe implements PipeTransform {
  transform(items: any[], field: string, value: string): any[] {
    if (!items) return [];
    if (!value || value.length == 0) return items;
    return items.filter(it => it[field] != null &&
      it[field].toLowerCase().indexOf(value.toLowerCase()) != -1);
  }
}
