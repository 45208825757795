import { Component } from '@angular/core';
//import { Http, Headers } from '@angular/http';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
//import { Router, ActivatedRoute } from '@angular/router';
import { Router,ActivatedRoute } from '@angular/router';
import { LoginService } from '../login/loginservice.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {

  loginForm: FormGroup;
  loginErrorMessage = "";
  errorMessage: any;
  confirmpwd: any;
  newpwd: any;
  constructor(private _fb: FormBuilder, private _avRoute: ActivatedRoute,
    private _loginService: LoginService, private _router: Router) {

    this.loginForm = this._fb.group({
      newpwd: ['', [Validators.required]],
      confirmpwd: ['', [Validators.required]],
    })
  }
  login() {

    if (!this.loginForm.valid) {
      return;
    }
    else {
      this._loginService.accountChange(this.loginForm.value)
        .subscribe((data) => {
          if (data["_body"] == "success") {
            localStorage.setItem('isUserLoggedIn', "true");
            this._router.navigate(['/dashboard/home']);
          }
          //else if (data["_body"] == "Reset Password") {
          //  this._router.navigate(['/change-password']);
          //}
          else {
            localStorage.setItem('isUserLoggedIn', "false");
            this.loginErrorMessage = data["_body"];
          }
        }, error => console.log(this.errorMessage = error))
    }
  }
}
