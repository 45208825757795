import { Component, OnInit} from '@angular/core';
import { AppService } from '../app.service';
import { NgbModal, NgbCalendar, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Http } from '@angular/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.css']
})
export class MasterPageComponent implements OnInit {
  myAppUrl: string = "";
  constructor(private appService: AppService, private _router: Router, private modalService: NgbModal, private _http: Http, private toastr: ToastrService,
    private ngbCalendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string>) { }
  ngOnInit() {
    this.myAppUrl = this.appService.getApiURL();
    this.GetRightsList();
  }
  GetRightsList() {
    this.appService.getloggedusername()
      .subscribe((data) => {
        var userId = data;
      }, error => console.log(error))
  };
}
