import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, Route, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  public MenuList: NavigationMenu[] = [];
  public SMList: NavigationMenu[] = [];
  public ASMList: NavigationMenu[] = [];
  public LTAAdminSubMenu: NavigationMenu[] = [];
  public AMSubMenu: NavigationMenu[] = [];
  public TPSM: NavigationMenu[] = [];
  public INSSubMenu: NavigationMenu[] = [];
  public PASubMenu: NavigationMenu[] = [];
  public distinctArray: string[] = [];
  userName = '';
  isExpanded = false;
  isNotifyExpanded = false;
  ActiveMName = '';
  mainUrl = "Main/";
  IsLoaded = false;
  IsSubLoaded = false;
  constructor(http: HttpClient, private _router: Router, @Inject('BASE_URL') baseUrl: string) {
    http.get(baseUrl + this.mainUrl + 'getloggedusername', { responseType: 'text' }).subscribe(udata => {
      this.userName = udata;
      //http.get<NavigationMenu[]>(baseUrl + this.mainUrl + 'GetUserRoles?userId=' + udata).subscribe(data => {

      //  for (var i = 0; i < data.length; i++) {
      //    if (this.distinctArray.indexOf(data[i].MenuName) < 0 && data[i].ShowMenu == 'true') {
      //      this.distinctArray.push(data[i].MenuName);
      //      data[i].Path = ('/dashboard/' + (data[i].Path == 'Index' ? 'home' : data[i].Path.toLowerCase()));
      //      if (data[i].MenuName == 'Manage Roles' || data[i].MenuName == 'Manage Users' || data[i].MenuName == 'Master Page'
      //        || data[i].MenuName == 'Mapping Page' || data[i].MenuName == '2FA' || data[i].MenuName == 'Vulnerability Scale') {
      //        this.LTAAdminSubMenu.push(data[i]);
      //      }
      //      else if (data[i].MenuName == 'Onboarding' || data[i].MenuName == 'Inventory'
      //        || data[i].MenuName == 'Retired Inventory') {
      //        this.TPSM.push(data[i]);
      //      }
      //      else {
      //        this.MenuList.push(data[i]);
      //      }
      //    }
      //  }
      //  if (this.TPSM.length > 0) {
      //    let newObj = {} as NavigationMenu;
      //    newObj.MenuName = "Third Party";
      //    newObj.Path = '/dashboard/' + 'onboarding';
      //    newObj.SubMenu = this.TPSM;
      //    this.MenuList.push(newObj);
      //  }
      //  let newObj = {} as NavigationMenu;
      //  newObj.MenuName = "Reports";
      //  newObj.Path = '/dashboard/' + 'insights';
      //  this.MenuList.push(newObj);
      //  if (this.LTAAdminSubMenu.length > 0) {
      //    let newObj = {} as NavigationMenu;
      //    newObj.MenuName = "Settings";
      //    newObj.Path = '/dashboard/' + 'vulnerabilityscale';
      //    newObj.SubMenu = this.LTAAdminSubMenu;
      //    this.MenuList.push(newObj);
      //  }
      //  this.IsLoaded = true;
      //}, error => {
      //  console.log(error)
      //  this._router.navigate(['/logout']);
      //});
    }, error => {
      console.log(error)
      this._router.navigate(['/logout']);
    });
  }
  makeActive(obj) {
    var path = this._router.url.split('?')[0];
    if (path == obj) {
      return true;
    }
    else {
      return false;
    }
  }
  makeSActive(path) {
    var path1 = this._router.url.split('?')[0]; //this._router.url;
    if (path == path1) {
      return true;
    }
    else {
      return false;
    }
  }
  IsSubExists() {
    if (this.IsSubLoaded && this.ASMList.length <= 0) {
      return true;
    }
    else {
      return false;
    }
  }
  PrevMName: any;
  showSubMenu(obj) {
    if (obj != undefined && obj != null && obj != '') {
      this.SMList = obj.SubMenu;
      var toggleElement = document.getElementById("navbarToggleExternalContent");
      //toggleElement.className = (this.isExpanded ? "collapse" : "expand");
      if (this.PrevMName != obj.MenuName) {
        this.isExpanded = true;
      }
      else {
        this.toggle();
      }
      this.PrevMName = obj.MenuName;
      //toggleElement.className = "expand";
    }

  }
  canActivateSubMenu(type) {
    if (type == "LTAAdminSubMenu") {
      var list = ['/dashboard/masterpage','/dashboard/usermanagement'];
      var exlist = list.filter(o => o == this._router.url);
      if (exlist != undefined && exlist != null && exlist.length > 0) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  openNotification() {
    this.isNotifyExpanded = !this.isNotifyExpanded;
  }
}
interface NavigationMenu {
  Icon: string;
  MenuName: string;
  Path: string;
  RightID: string;
  RightName: string;
  ShowMenu: string;
  SubMenu: NavigationMenu[];
  id: string;
}
