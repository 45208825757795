import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http"
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private apiURL: string = 'Main/';
  constructor(private http: HttpClient) {
  }
  private _subject = new Subject<any>();
  clickEvent(event) {
    this._subject.next(event);
  }
  get events$() {
    return this._subject.asObservable();
  }
  getApiURL(): string {
    return this.apiURL;
  }
  getloggedusername(): Observable<any> {
    return this.http.get<any>(this.apiURL + 'getuseraccessname');
  }
  IsUserFirstLogin(): Observable<any> {
    return this.http.get<any>(this.apiURL + 'IsUserFirstLogin');
  }
  
  getAllCommonEvidence(MappingId: string, Page: string, UploadName: string): Observable<any> {
    return this.http.get<any>(this.apiURL + 'GetAllCommonEvidence?MappingId=' + MappingId + '&Page=' + Page + '&UploadName=' + UploadName);
  }
 
  getAllCommonVersionLog(Id: string, Page: string, MappingType: string, ChangeDesc: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllCommonVersionLog?MappingId=' + Id + "&Page=" + Page + "&MappingType=" + MappingType + "&ChangeDesc=" + ChangeDesc);
  }
  
  getUsers(): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'getusers?userid=');
  }
  getUser(userid: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'getusers?userid=' + userid);
  }
 
  getAllWhatData(Id): Observable<any> {
    return this.http.get<any>(this.apiURL + 'GetAllWhatData?Id=' + Id);
  }
  getAllWhereDataStored(Id): Observable<any> {
    return this.http.get<any>(this.apiURL + 'GetAllWhereDataStored?Id=' + Id);
  }
  getAllImpactedBusinessProcesses(Id): Observable<any> {
    return this.http.get<any>(this.apiURL + 'GetAllImpactedBusinessProcesses?Id=' + Id);
  }
  getAllWhatDataCollect(Id): Observable<any> {
    return this.http.get<any>(this.apiURL + 'GetAllWhatDataCollect?Id=' + Id);
  }
  getAllConfirmLawfulBasis(Id): Observable<any> {
    return this.http.get<any>(this.apiURL + 'GetAllConfirmLawfulBasis?Id=' + Id);
  }
  getAllWhoseData(Id): Observable<any> {
    return this.http.get<any>(this.apiURL + 'GetAllWhoseData?Id=' + Id);
  }
  GetAllDataAdditionalApprovals(RiskId): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllDataAdditionalApprovals?RiskId=' + RiskId);
  }
  GetUserTeamMapping(userId): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetUserTeamMapping?userId=' + userId);
  }
  getAllWhoAccessData(Id): Observable<any> {
    return this.http.get<any>(this.apiURL + 'GetAllWhoAccessData?Id=' + Id);
  }
  getAllWhyCollectingData(Id): Observable<any> {
    return this.http.get<any>(this.apiURL + 'GetAllWhyCollectingData?Id=' + Id);
  }
  getAllBusinessProcess(BusinessProcessId): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllBusinessProcess?BusinessProcessId=' + BusinessProcessId);
  }
  getAllConsents(Id): Observable<any> {
    return this.http.get<any>(this.apiURL + 'GetAllConsents?Id=' + Id);
  }
  getAllWhenObtainData(Id): Observable<any> {
    return this.http.get<any>(this.apiURL + 'GetAllWhenObtainData?Id=' + Id);
  }
  getAllUniqDataReference(Id): Observable<any> {
    return this.http.get<any>(this.apiURL + 'GetAllUniqDataReference?Id=' + Id);
  }
  getAllGDPROverview(Id: string): Observable<any> {
    return this.http.get<any>(this.apiURL + 'GetAllGDPROverview?Id=' + Id);
  }
  GetAllDataSubjectsColumnDisplay(): Observable<any> {
    return this.http.get<any>(this.apiURL + 'GetAllDataSubjectsColumnDisplay');
  }
  
  GetAllCommonEvidence(MappingId: string, Page: string, UploadName: string): Observable<any> {
    return this.http.get<any>(this.apiURL + 'GetAllCommonEvidence?MappingId=' + MappingId + "&Page=" + Page + '&UploadName=' + UploadName);
  }
  GetAllControlPeriod(Id: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllControlPeriod?Id=' + Id);
  }
  GetAllCommentType(Id: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllCommentType?Id=' + Id);
  }
  
  getAllBusinessLine(BusinessLineId: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllBusinessLine?BusinessLineId=' + BusinessLineId);
  }
  getAllDepartment(Id: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllDepartment?Id=' + Id);
  }
  getAllCommentType(Id: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllCommentType?Id=' + Id);
  }
  
  getAllTeam(Id: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllTeam?Id=' + Id);
  }

  getAllPeriods(Id: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllPeriods?Id=' + Id);
  }
  getAllRegion(RegionId: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllRegion?RegionId=' + RegionId);
  }
  getAllCountry(CountryId: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllCountry?CountryId=' + CountryId);
  }
  
  getAllTeamMappingbyId(userId: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllTeamMappingbyId?userId=' + userId);
  }
  getAllBusinessLineMappingbyId(userId: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllBusinessLineMappingbyId?userId=' + userId);
  }
  GetBusinessMapping(userId: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetBusinessMapping?userId=' + userId);
  }
  GetuserDepartmentMapping(userId: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetuserDepartmentMapping?userId=' + userId);
  }
  GetAllDeadlines(Id: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllDeadlines?Id=' + Id);
  }

  GetAllExternalLinks(Id: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllExternalLinks?Id=' + Id);
  }
  
  GetAllDataApprovals(RiskId: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllDataApprovals?RiskId=' + RiskId);
  }

  
  GetUserByTeam(TeamId: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetUserByTeam?TeamId=' + TeamId);
  }

  
  

  GetAllDataComments(RiskId: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllDataComments?RiskId=' + RiskId);
  }

  //GetAllbreachComments(RiskId): Observable<any[]> {
  //  return this.http.get<any[]>(this.apiURL + 'GetAllbreachComments?RiskId=' + RiskId);
  //}

  GetAllCountry(CountryId: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + 'GetAllCountry?CountryId=' + CountryId);
  }
}
