import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { AppService} from '../app.service';
import { DataTableDirective } from "angular-datatables";
import { Http, Response } from '@angular/http';
import { NgbModal, ModalDismissReasons, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-data-subjects',
  templateUrl: './data-subjects.component.html',
  styleUrls: ['./data-subjects.component.css']
})
export class DataSubjectsComponent implements OnInit {
  myAppUrl: string = "";
  IsExpanded = false;
  showAddwindow = false;
  IsAddDisabled = false;
  IsAdminExpanded = false;
  public settings = {};
  public optionsWhatData = [];
  optionsWhereDataStored = [];
  optionsImpactedBusinessProcesses = []; optionsHowObtainConsent = []; optionsWhyCollectingData = []; optionsWhatDataCollect = [];
  optionsWhenObtainData = []; optionsWhoAccessData = []; optionsDataController = []; optionsSupervisoryAuthority = [];
  optionsJointController = []; optionsDataProtectionOfficer = [];
  Prevvalue: any;
  IsAllowEdit = false;
  closeResult = '';
  searchTextGrid: string;
  CommentTypeList: any[];
  //Master Data Model Controls
  viewChapterNoList: boolean = false;
  viewChapterNameList: boolean = false;
  viewArticleNoList: boolean = false;
  viewArticleNameList: boolean = false;
  viewParagraphNoList: boolean = false;
  viewRegulatorList: boolean = false;
  viewPolicyRFrameworkList: boolean = false;
  WhatDataList: any; WhereDataStoredList: any; ImpactedBusinessProcessesList: any; HowObtainConsentList: any; WhyCollectingDataList: any;
  WhatDataCollectList: any; WhenObtainDataList: any; WhoAccessDataList: any; UserList: any; WhoseDataList: any; UniqDataReferenceList: any;
  selectModel: any;

  //Master Lists
  gridData: any;
  PRFList: any[];
  ChapterNameList: any[];
  ArticleNameList: any[];
  RegulatorList: any[];
  ChapterNoList: any[];
  ArticleNoList: any[];
  ParagraphNoList: any[];
  LogListdata: any[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  timerSubscription: Subscription;
  //@ViewChild('content', { static: false }) private content;
  @ViewChild(ConfirmModalComponent, { static: false }) child: ConfirmModalComponent;
  AllUsersList: any;
  PrevPACommentsList: any;
  CommentsAllList: any;
  ControlPeriodList: any[];
  TeamList: any[];
  IsAllAdditionalApproved: boolean;
  SingleSIApprovals: any;
  constructor(private appService: AppService, private modalService: NgbModal, private _http: Http, private toastr: ToastrService,
    config: NgbDropdownConfig) {
    config.autoClose = false;
  }
  ngOnInit() {
    this.myAppUrl = this.appService.getApiURL();
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      searching: false,

      columns: [null, null, null, null, { orderable: false }]
    };
    this.GetRightsList();
    this.settings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      enableCheckAll: true,
      selectAllText: "Select All",
      unSelectAllText: "Unselect All",
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: "search",
      noDataAvailablePlaceholderText: "No Data Available",
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
      autoPosition: false

    };

    this.getallmasterdata(0);
    this.getallgriddata();
    this.getallcolumndisplay();
    this.appService.events$.forEach(event => {
      if (event == "canceled") {
        this.cancel();
      }
    });
  }
  getallgriddata() {
    this.appService.getAllGDPROverview("")
      .subscribe((data) => {
        this.gridData = data;
      }, error => console.log(error))
    this.appService.GetAllControlPeriod("")
      .subscribe((data) => {
        this.ControlPeriodList = data;
      }, error => console.log(error))
    this.appService.getAllCommentType("")
      .subscribe((data) => {
        this.CommentTypeList = data;
      }, error => console.log(error))
    this.appService.getAllTeam("")
      .subscribe((data) => {
        this.TeamList = data;
      }, error => console.log(error))
    this.appService.getUsers()
      .subscribe((data) => {
        this.AllUsersList = data.filter(o => o.Status == "Active");
      }, error => console.log(error))
  }
  getallmasterdata(obj) {
    if (obj == 0 || obj == 1) {
      this.appService.getAllWhatData("")
        .subscribe((data) => {
          this.WhatDataList = data;
          this.bindWhatData(this.WhatDataList);
        }, error => console.log(error))
    }
    if (obj == 0 || obj == 2) {
      this.appService.getAllWhereDataStored("")
        .subscribe((data) => {
          this.WhereDataStoredList = data;
          this.bindWhereDataStored(this.WhereDataStoredList);
        }, error => console.log(error))
    }
    if (obj == 0 || obj == 3) {
      this.appService.getAllImpactedBusinessProcesses("")
        .subscribe((data) => {
          this.ImpactedBusinessProcessesList = data;
          this.bindImpactedBusinessProcesses(this.ImpactedBusinessProcessesList);
        }, error => console.log(error))
    }
    if (obj == 0 || obj == 4) {
      this.appService.getAllConfirmLawfulBasis("")
        .subscribe((data) => {
          this.HowObtainConsentList = data;
          this.bindHowObtainConsent(this.HowObtainConsentList);
        }, error => console.log(error))
    }
    if (obj == 0 || obj == 5) {
      this.appService.getAllWhyCollectingData("")
        .subscribe((data) => {
          this.WhyCollectingDataList = data;
          this.bindWhyCollectingData(this.WhyCollectingDataList);
        }, error => console.log(error))
    }
    if (obj == 0 || obj == 6) {
      this.appService.getAllWhatDataCollect("")
        .subscribe((data) => {
          this.WhatDataCollectList = data;
          this.bindWhatDataCollect(this.WhatDataCollectList);
        }, error => console.log(error))
    }
    if (obj == 0 || obj == 7) {
      this.appService.getAllWhenObtainData("")
        .subscribe((data) => {
          this.WhenObtainDataList = data;
          this.bindWhenObtainData(this.WhenObtainDataList);
        }, error => console.log(error))
    }
    if (obj == 0 || obj == 8) {
      this.appService.getAllWhoAccessData("")
        .subscribe((data) => {
          this.WhoAccessDataList = data;
          this.bindWhoAccessData(this.WhoAccessDataList);
        }, error => console.log(error))
    }
    if (obj == 0 || obj == 9) {
      this.appService.getAllWhoseData("")
        .subscribe((data) => {
          this.WhoseDataList = data;
          if (this.editMode) {
            this.selectModel.WhoseData = this.getdynamicobject(this.Prevvalue.WhoseDataId, "WhoseDataList");
          }
        }, error => console.log(error))
    }
    if (obj == 0 || obj == 10) {
      this.appService.getAllUniqDataReference("")
        .subscribe((data) => {
          this.UniqDataReferenceList = data;
          if (this.editMode) {
            this.selectModel.UniqDataReference = this.getdynamicobject(this.Prevvalue.UniqDataReferenceId, "UniqDataReferenceList");
          }
        }, error => console.log(error))
    }
    if (obj == 0) {
      this.appService.getUsers()
        .subscribe((data) => {
          this.UserList = data.filter(o => o.Status == "Active");
          this.bindDataController(this.UserList);
          this.bindSupervisoryAuthority(this.UserList);
          this.bindjointcontrollerdata(this.UserList);
          this.binddataprotectionofficerdata(this.UserList);
        }, error => console.log(error))
    }
  }
  searchControls = {};
  IsShowSearch: boolean = false;
  ShowSearch() {
    this.IsShowSearch = !this.IsShowSearch;
  }
  showHideColsHeader: any;
  getallcolumndisplay() {
    this.appService.GetAllDataSubjectsColumnDisplay()
      .subscribe((data) => {
        if (data == undefined || data == null || data.length <= 0) {
          this.showHideColsHeader = {
            All: true,
            UniqDataReference: true, WhoseData: true, WhatDataCollect: true, WhatData: true, WhyCollectingData: true, ApprovalStatus: true
          };
        }
        else {
          var lobj = data[0];
          this.showHideColsHeader = {
            UniqDataReference: this.stringtoBoolean(lobj.UniqDataReference),
            WhoseData: this.stringtoBoolean(lobj.WhoseData),
            WhatDataCollect: this.stringtoBoolean(lobj.WhatDataCollect),
            WhatData: this.stringtoBoolean(lobj.WhatData),
            WhyCollectingData: this.stringtoBoolean(lobj.WhyCollectingData),
            ApprovalStatus: this.stringtoBoolean(lobj.ApprovalStatus),
            All: (!this.stringtoBoolean(lobj.UniqDataReference) ? false : !this.stringtoBoolean(lobj.WhoseData) ? false : !this.stringtoBoolean(lobj.WhatDataCollect) ? false :
              !this.stringtoBoolean(lobj.WhatData) ? false : !this.stringtoBoolean(lobj.WhyCollectingData) ? false : !this.stringtoBoolean(lobj.ApprovalStatus) ? false : true),
          };
        }
      }, error => console.log(error))
  }
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  order: string = '';
  reverse: boolean = true;
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  unique(array, propertyName) {
    return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
  }

  AllDBList: any; DataBreachList: any;

  IsNullOrEmty(value) {
    return ((value == undefined || value == null || value == '') ? '' : value)
  }

  formatDateTime(data) {
    if (data != undefined && data != '' && data != null) {
      var tcdate = new Date(Date.parse(data));
      return this.getFormattedDateTime(tcdate);
    }
    else {
      return '';
    }
  }
  getYear(data) {
    if (data != undefined && data != '' && data != null) {
      var tcdate = new Date(Date.parse(data));
      return tcdate.getFullYear();
    }
    else {
      return '';
    }
  }
  getMonth(data) {
    if (data != undefined && data != '' && data != null) {
      var tcdate = new Date(Date.parse(data));
      return tcdate.toLocaleString('default', { month: 'long' });
    }
    else {
      return '';
    }
  }

  GetViewLog(Id, content) {
    this.GetAllLogInfo(Id);
    this.openXl(content);
  }
  showArchivedChanges(content) {
    this.GetAllLogInfo(this.selectModel.Id);
    this.openXl(content);
  }
  LogListdataAll: any; ChangesSinceLastApproval: any; PreviousReviewList: any;
  GetAllLogInfo(Id) {
    this.PreviousReviewList = []; this.ChangesSinceLastApproval = [];
    this.appService.getAllCommonVersionLog(Id, 'DataCollection', 'Log', '')
      .subscribe((data) => {
        this.LogListdata = data;
        this.LogListdataAll = Object.assign([], data);
        for (var i = 0; i < this.LogListdataAll.length; i++) {
          var date = this.LogListdataAll[i].CreatedDate;
          this.LogListdataAll[i].CreatedDate = this.formatDateTime(date);
          this.LogListdataAll[i].CreatedByDate = this.formatDate(date);
        }
        this.PreviousReviewList = this.LogListdataAll.filter(o => o.FTo == 'Approved' && o.Field == 'Data Owner Approval');
        if (Boolean(this.PreviousReviewList) && this.PreviousReviewList.length > 0) {
          var lstReview = this.PreviousReviewList[0];
          var fromDateParts = lstReview.CreatedByDate.split("/");
          var start = new Date(+fromDateParts[2], fromDateParts[1] - 1, +fromDateParts[0]);
          var list = this.LogListdataAll.filter(item => {
            var dateParts = item.CreatedByDate.split("/");
            let date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            return date >= start && item.Id > lstReview.Id;
          });
          this.ChangesSinceLastApproval = list;
        }
        else {
          //this.ChangesSinceLastApproval = this.LogListdata;
        }
      }, error => console.log(error))
  }
  FilterLogsInfo() {
    var LogListdata = [];
    if ((Boolean(this.selectModel.FilterFrom) && Boolean(this.selectModel.FilterTo)) || Boolean(this.selectModel.FilterUser)) {
      if (Boolean(this.selectModel.FilterFrom) && Boolean(this.selectModel.FilterTo)) {
        var fromDateParts = this.selectModel.FilterFrom.split("/");
        var start = new Date(+fromDateParts[2], fromDateParts[1] - 1, +fromDateParts[0]);
        var toDateParts = this.selectModel.FilterTo.split("/");
        var end = new Date(+toDateParts[2], toDateParts[1] - 1, +toDateParts[0]);
        var list = this.LogListdataAll.filter(item => {
          var dateParts = item.CreatedDate.split("/");
          let date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
          return date >= start && date <= end && item.CreatedBy == (Boolean(this.selectModel.FilterUser) ? this.selectModel.FilterUser : item.CreatedBy);
        });
        LogListdata.push.apply(LogListdata, list);
      }
      else if (Boolean(this.selectModel.FilterUser)) {
        LogListdata.push.apply(LogListdata, this.LogListdataAll.filter(o => o.CreatedBy == this.selectModel.FilterUser));
      }
      this.LogListdata = LogListdata;
    }
    else if (Boolean(this.selectModel.FilterReivew)) {
      var fromDateParts = this.selectModel.FilterReivew.CreatedDate.split("/");
      var start = new Date(+fromDateParts[2], fromDateParts[1] - 1, +fromDateParts[0]);
      var list = this.LogListdataAll.filter(item => {
        var dateParts = item.CreatedDate.split("/");
        let date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        return date <= start && item.Id <= this.selectModel.FilterReivew.Id;
      });
      LogListdata.push.apply(LogListdata, list);
      this.LogListdata = LogListdata;
    }
    else {
      LogListdata.push.apply(LogListdata, this.LogListdataAll);
      this.LogListdata = LogListdata;
    }
  }

  ExpandPane() {
    this.IsAdminExpanded = false;
    this.IsExpanded = !this.IsExpanded;
  }
  ExpandAdminPane() {
    this.IsExpanded = false;
    /*    this.cleardata();*/
    this.IsAdminExpanded = !this.IsAdminExpanded;
  }
  editMode = false;
  IsReadOnly = true;
  Showadd() {
    this.selectModel = {
      UniqDataReference: '', WhoseData: '', RiskLevel: '', DataThreatLevel: '', RiskRImpact: '', RRiskLevel: '', RDataThreatLevel: '', RRiskRImpact: '',
      IRiskLevel: '', IDataThreatLevel: '', IRiskRImpact: '', FilterUser:'',
    };
    this.IsAllowEdit = false;
    this.CommentsData = []
    this.DataBreach = [];
    this.ChangesSinceLastApproval = [];
    this.PACommentsList = [];
    this.SIApprovals = [];
    this.AdditionalApprovals = [];
    this.showAddwindow = true;
    this.showAddReviewComment = false;
    this.IsAddDisabled = false;
    this.editMode = false;
    this.IsExpanded = false;
    this.IsAdminExpanded = false;
  }
  allowEdit() {
    this.IsAllowEdit = true;
  }
  add(selectModel) {
    if (selectModel.WhoseData == undefined || selectModel.WhoseData == null || selectModel.WhoseData == "") {
      this.toastr.warning('Please select "Whose Data Do You Collect?"', "");
    }
    else if (selectModel.UniqDataReference == undefined || selectModel.UniqDataReference == null || selectModel.UniqDataReference == "") {
      this.toastr.warning('Please select "Data Collection Reference"', "");
    }
    else {
      this.IsAddDisabled = true;
      var cmnt = this.CommentsData;
      var DataBreach = this.DataBreach; var BreachList = '';
      //var GDPRProcessFlow = this.GDPRProcessFlow;
      var respidlist = '', accidlist = '', infidlist = '', considlist = '', regidlist = '',
        DataControllerIdList = '', SupervisoryAuthorityIdList = '', JointControllerIdList = '',
        DataProtectionOfficerIdList = '';
      var respnamelist = '', accnamelist = '', infnamelist = '', consnamelist = '', regnamelist = '',
        DataControllerNameList = '', SupervisoryAuthorityNameList = '', JointControllerNameList = '',
        DataProtectionOfficerNameList = '';
      if (selectModel.DataControllerselected != undefined && selectModel.DataControllerselected != null && selectModel.DataControllerselected.length > 0) {
        for (var i = 0; i < selectModel.DataControllerselected.length; i++) {
          if (i == selectModel.DataControllerselected.length - 1) {
            DataControllerIdList += selectModel.DataControllerselected[i].item_id;
            DataControllerNameList += selectModel.DataControllerselected[i].item_text;
          }
          else {
            DataControllerIdList += selectModel.DataControllerselected[i].item_id + ',';
            DataControllerNameList += selectModel.DataControllerselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.SupervisoryAuthorityselected != undefined && selectModel.SupervisoryAuthorityselected != null && selectModel.SupervisoryAuthorityselected.length > 0) {
        for (var i = 0; i < selectModel.SupervisoryAuthorityselected.length; i++) {
          if (i == selectModel.SupervisoryAuthorityselected.length - 1) {
            SupervisoryAuthorityIdList += selectModel.SupervisoryAuthorityselected[i].item_id;
            SupervisoryAuthorityNameList += selectModel.SupervisoryAuthorityselected[i].item_text;
          }
          else {
            SupervisoryAuthorityIdList += selectModel.SupervisoryAuthorityselected[i].item_id + ',';
            SupervisoryAuthorityNameList += selectModel.SupervisoryAuthorityselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.JointControllerselected != undefined && selectModel.JointControllerselected != null && selectModel.JointControllerselected.length > 0) {
        for (var i = 0; i < selectModel.JointControllerselected.length; i++) {
          if (i == selectModel.JointControllerselected.length - 1) {
            JointControllerIdList += selectModel.JointControllerselected[i].item_id;
            JointControllerNameList += selectModel.JointControllerselected[i].item_text;
          }
          else {
            JointControllerIdList += selectModel.JointControllerselected[i].item_id + ',';
            JointControllerNameList += selectModel.JointControllerselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.DataProtectionOfficerselected != undefined && selectModel.DataProtectionOfficerselected != null && selectModel.DataProtectionOfficerselected.length > 0) {
        for (var i = 0; i < selectModel.DataProtectionOfficerselected.length; i++) {
          if (i == selectModel.DataProtectionOfficerselected.length - 1) {
            DataProtectionOfficerIdList += selectModel.DataProtectionOfficerselected[i].item_id;
            DataProtectionOfficerNameList += selectModel.DataProtectionOfficerselected[i].item_text;
          }
          else {
            DataProtectionOfficerIdList += selectModel.DataProtectionOfficerselected[i].item_id + ',';
            DataProtectionOfficerNameList += selectModel.DataProtectionOfficerselected[i].item_text + ',';
          }
        }
      }

      // GDPR Overview Basic Multi Selections
      var WhenObtainDataIdList = '', WhatDataIdList = '', WhyCollectingDataIdList = '', HowObtainConsentIdList = '',
        WhereDataStoredIdList = '', WhatDataCollectIdList = '', WhoAccessDataIdList = '', ImpactedBusinessProcessesIdList = '';
      var WhenObtainDataNameList = '', WhatDataNameList = '', WhyCollectingDataNameList = '', HowObtainConsentNameList = '',
        WhereDataStoredNameList = '', WhatDataCollectNameList = '', WhoAccessDataNameList = '', ImpactedBusinessProcessesNameList = '';
      if (selectModel.WhenObtainDataselected != undefined && selectModel.WhenObtainDataselected != null && selectModel.WhenObtainDataselected.length > 0) {
        for (var i = 0; i < selectModel.WhenObtainDataselected.length; i++) {
          if (i == selectModel.WhenObtainDataselected.length - 1) {
            WhenObtainDataIdList += selectModel.WhenObtainDataselected[i].item_id;
            WhenObtainDataNameList += selectModel.WhenObtainDataselected[i].item_text;
          }
          else {
            WhenObtainDataIdList += selectModel.WhenObtainDataselected[i].item_id + ',';
            WhenObtainDataNameList += selectModel.WhenObtainDataselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.WhatDataselected != undefined && selectModel.WhatDataselected != null && selectModel.WhatDataselected.length > 0) {
        for (var i = 0; i < selectModel.WhatDataselected.length; i++) {
          if (i == selectModel.WhatDataselected.length - 1) {
            WhatDataIdList += selectModel.WhatDataselected[i].item_id;
            WhatDataNameList += selectModel.WhatDataselected[i].item_text;
          }
          else {
            WhatDataIdList += selectModel.WhatDataselected[i].item_id + ',';
            WhatDataNameList += selectModel.WhatDataselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.WhyCollectingDataselected != undefined && selectModel.WhyCollectingDataselected != null && selectModel.WhyCollectingDataselected.length > 0) {
        for (var i = 0; i < selectModel.WhyCollectingDataselected.length; i++) {
          if (i == selectModel.WhyCollectingDataselected.length - 1) {
            WhyCollectingDataIdList += selectModel.WhyCollectingDataselected[i].item_id;
            WhyCollectingDataNameList += selectModel.WhyCollectingDataselected[i].item_text;
          }
          else {
            WhyCollectingDataIdList += selectModel.WhyCollectingDataselected[i].item_id + ',';
            WhyCollectingDataNameList += selectModel.WhyCollectingDataselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.HowObtainConsentselected != undefined && selectModel.HowObtainConsentselected != null && selectModel.HowObtainConsentselected.length > 0) {
        for (var i = 0; i < selectModel.HowObtainConsentselected.length; i++) {
          if (i == selectModel.HowObtainConsentselected.length - 1) {
            HowObtainConsentIdList += selectModel.HowObtainConsentselected[i].item_id;
            HowObtainConsentNameList += selectModel.HowObtainConsentselected[i].item_text;
          }
          else {
            HowObtainConsentIdList += selectModel.HowObtainConsentselected[i].item_id + ',';
            HowObtainConsentNameList += selectModel.HowObtainConsentselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.WhereDataStoredselected != undefined && selectModel.WhereDataStoredselected != null && selectModel.WhereDataStoredselected.length > 0) {
        for (var i = 0; i < selectModel.WhereDataStoredselected.length; i++) {
          if (i == selectModel.WhereDataStoredselected.length - 1) {
            WhereDataStoredIdList += selectModel.WhereDataStoredselected[i].item_id;
            WhereDataStoredNameList += selectModel.WhereDataStoredselected[i].item_text;
          }
          else {
            WhereDataStoredIdList += selectModel.WhereDataStoredselected[i].item_id + ',';
            WhereDataStoredNameList += selectModel.WhereDataStoredselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.WhatDataCollectselected != undefined && selectModel.WhatDataCollectselected != null && selectModel.WhatDataCollectselected.length > 0) {
        for (var i = 0; i < selectModel.WhatDataCollectselected.length; i++) {
          if (i == selectModel.WhatDataCollectselected.length - 1) {
            WhatDataCollectIdList += selectModel.WhatDataCollectselected[i].item_id;
            WhatDataCollectNameList += selectModel.WhatDataCollectselected[i].item_text;
          }
          else {
            WhatDataCollectIdList += selectModel.WhatDataCollectselected[i].item_id + ',';
            WhatDataCollectNameList += selectModel.WhatDataCollectselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.WhoAccessDataselected != undefined && selectModel.WhoAccessDataselected != null && selectModel.WhoAccessDataselected.length > 0) {
        for (var i = 0; i < selectModel.WhoAccessDataselected.length; i++) {
          if (i == selectModel.WhoAccessDataselected.length - 1) {
            WhoAccessDataIdList += selectModel.WhoAccessDataselected[i].item_id;
            WhoAccessDataNameList += selectModel.WhoAccessDataselected[i].item_text;
          }
          else {
            WhoAccessDataIdList += selectModel.WhoAccessDataselected[i].item_id + ',';
            WhoAccessDataNameList += selectModel.WhoAccessDataselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.ImpactedBusinessProcessesselected != undefined && selectModel.ImpactedBusinessProcessesselected != null && selectModel.ImpactedBusinessProcessesselected.length > 0) {
        for (var i = 0; i < selectModel.ImpactedBusinessProcessesselected.length; i++) {
          if (i == selectModel.ImpactedBusinessProcessesselected.length - 1) {
            ImpactedBusinessProcessesIdList += selectModel.ImpactedBusinessProcessesselected[i].item_id;
            ImpactedBusinessProcessesNameList += selectModel.ImpactedBusinessProcessesselected[i].item_text;
          }
          else {
            ImpactedBusinessProcessesIdList += selectModel.ImpactedBusinessProcessesselected[i].item_id + ',';
            ImpactedBusinessProcessesNameList += selectModel.ImpactedBusinessProcessesselected[i].item_text + ',';
          }
        }
      }
      for (var i = 0; i < this.DataBreach.length; i++) {
        BreachList += this.DataBreach[i].Id;
      }
      var array = {
        AssetComments: JSON.stringify(cmnt),
        DataBreach: JSON.stringify(DataBreach),
        //ProcessFlow: JSON.stringify(GDPRProcessFlow),
        WhoseDataId: (selectModel.WhoseData == undefined || selectModel.WhoseData == null) ? '' : selectModel.WhoseData.Id,
        WhoseData: (selectModel.WhoseData == undefined || selectModel.WhoseData == null) ? '' : selectModel.WhoseData.WhoseData,
        WhatDataId: (selectModel.WhatData == undefined || selectModel.WhatData == null) ? '' : selectModel.WhatData.Id,
        WhereDataStoredId: (selectModel.WhereDataStored == undefined || selectModel.WhereDataStored == null) ? '' : selectModel.WhereDataStored.Id,
        ImpactedBusinessProcessesId: (selectModel.ImpactedBusinessProcesses == undefined || selectModel.ImpactedBusinessProcesses == null) ? '' : selectModel.ImpactedBusinessProcesses.Id,
        UniqDataReferenceId: (selectModel.UniqDataReference == undefined || selectModel.UniqDataReference == null) ? '' : selectModel.UniqDataReference.Id,
        UniqDataReference: (selectModel.UniqDataReference == undefined || selectModel.UniqDataReference == null) ? '' : selectModel.UniqDataReference.UniqDataReference,
        WhyCollectingDataId: (selectModel.WhyCollectingData == undefined || selectModel.WhyCollectingData == null) ? '' : selectModel.WhyCollectingData.Id,
        WhatDataCollectId: (selectModel.WhatDataCollect == undefined || selectModel.WhatDataCollect == null) ? '' : selectModel.WhatDataCollect.Id,
        WhenObtainDataId: (selectModel.WhenObtainData == undefined || selectModel.WhenObtainData == null) ? '' : selectModel.WhenObtainData.Id,
        HowObtainConsentId: (selectModel.HowObtainConsent == undefined || selectModel.HowObtainConsent == null) ? '' : selectModel.HowObtainConsent.Id,
        WhoAccessDataId: (selectModel.WhoAccessData == undefined || selectModel.WhoAccessData == null) ? '' : selectModel.WhoAccessData.Id,
        WhenObtainDataIdList: WhenObtainDataIdList,
        WhenObtainDataNameList: WhenObtainDataNameList,
        WhatDataIdList: WhatDataIdList,
        WhatDataNameList: WhatDataNameList,
        WhyCollectingDataIdList: WhyCollectingDataIdList,
        WhyCollectingDataNameList: WhyCollectingDataNameList,
        HowObtainConsentIdList: HowObtainConsentIdList,
        HowObtainConsentNameList: HowObtainConsentNameList,
        WhereDataStoredIdList: WhereDataStoredIdList,
        WhereDataStoredNameList: WhereDataStoredNameList,
        WhatDataCollectIdList: WhatDataCollectIdList,
        WhatDataCollectNameList: WhatDataCollectNameList,
        WhoAccessDataIdList: WhoAccessDataIdList,
        WhoAccessDataNameList: WhoAccessDataNameList,
        ImpactedBusinessProcessesIdList: ImpactedBusinessProcessesIdList,
        ImpactedBusinessProcessesNameList: ImpactedBusinessProcessesNameList,

        RiskRImpact: selectModel.RiskRImpact,
        RiskLevel: selectModel.RiskLevel,
        DataThreatLevel: selectModel.DataThreatLevel,
        RRiskRImpact: selectModel.RRiskRImpact,
        RRiskLevel: selectModel.RRiskLevel,
        RDataThreatLevel: selectModel.RDataThreatLevel,
        IRiskRImpact: selectModel.IRiskRImpact,
        IRiskLevel: selectModel.IRiskLevel,
        IDataThreatLevel: selectModel.IDataThreatLevel,
        Integrity: selectModel.Integrity,
        Confidentiality: selectModel.Confidentiality,
        Availability: selectModel.Availability,

        SupervisoryAuthorityId: (selectModel.SupervisoryAuthority == undefined || selectModel.SupervisoryAuthority == null) ? '' : selectModel.SupervisoryAuthority.Id,
        PrivacyImpactAssessmentsId: (selectModel.PrivacyImpactAssessments == undefined || selectModel.PrivacyImpactAssessments == null) ? '' : selectModel.PrivacyImpactAssessments.Id,
        SupervisoryAuthorityApprovalId: (selectModel.SupervisoryAuthorityApproval == undefined || selectModel.SupervisoryAuthorityApproval == null) ? '' : selectModel.SupervisoryAuthorityApproval.Id,
        DemostrateConsentId: (selectModel.DemostrateConsent == undefined || selectModel.DemostrateConsent == null) ? '' : selectModel.DemostrateConsent.Id,
        ParentalConsentId: (selectModel.ParentalConsent == undefined || selectModel.ParentalConsent == null) ? '' : selectModel.ParentalConsent.Id,
        CPFrom: selectModel.CPFrom,
        CPTo: selectModel.CPTo,
        CPEDate: selectModel.CPEDate,
        DataProcessorsId: (selectModel.DataProcessors == undefined || selectModel.DataProcessors == null) ? '' : selectModel.DataProcessors.Id,
        DataVisibilityHasId: (selectModel.DataVisibilityHas == undefined || selectModel.DataVisibilityHas == null) ? '' : selectModel.DataVisibilityHas.Id,
        DataVisibilityNeedId: (selectModel.DataVisibilityNeed == undefined || selectModel.DataVisibilityNeed == null) ? '' : selectModel.DataVisibilityNeed.Id,
        DataControllerId: (selectModel.DataController == undefined || selectModel.DataController == null) ? '' : selectModel.DataController.Id,
        DSC: selectModel.DSC,
        DCR: selectModel.DCR,
        ReCertDate: (selectModel.ReCertDate == undefined || selectModel.ReCertDate == null) ? '' : selectModel.ReCertDate,
        OtherRegulationsId: (selectModel.OtherRegulationsId == undefined || selectModel.OtherRegulationsId == null) ? '' : selectModel.OtherRegulationsId.Id,
        VendorNameId: (selectModel.VendorNameId == undefined || selectModel.VendorNameId == null) ? '' : selectModel.VendorNameId.Id,
        BackupStatusId: (selectModel.BackupStatus == undefined || selectModel.BackupStatus == null) ? '' : selectModel.BackupStatus.Id,
        IsCPE: (selectModel.IsCPE == undefined || selectModel.IsCPE == null) ? '' : selectModel.IsCPE,
        ResponsibleIdList: respidlist,
        ResponsibleNameList: respnamelist,
        AccountableIdList: accidlist,
        AccountableNameList: accnamelist,
        ConsultedIdList: considlist,
        ConsultedNameList: consnamelist,
        InformedIdList: infidlist,
        InformedNameList: infnamelist,
        OtherRegulationsIdList: regidlist,
        OtherRegulationsNameList: regnamelist,
        DataControllerIdList: DataControllerIdList,
        DataControllerNameList: DataControllerNameList,
        SupervisoryAuthorityIdList: SupervisoryAuthorityIdList,
        SupervisoryAuthorityNameList: SupervisoryAuthorityNameList,
        JointControllerIdList: JointControllerIdList,
        JointControllerNameList: JointControllerNameList,
        DataProtectionOfficerIdList: DataProtectionOfficerIdList,
        DataProtectionOfficerNameList: DataProtectionOfficerNameList,
        StorageLimitation: selectModel.StorageLimitation,
        PurposeLimitation: selectModel.PurposeLimitation,
        PurposeOfProcessing: selectModel.PurposeOfProcessing,
        IsConsentRequired: selectModel.IsConsentRequired,
        OtherConsentRequired: selectModel.OtherConsentRequired,
        IsParentalConsentRequired: selectModel.IsParentalConsentRequired,
        OtherParentalConsentRequired: selectModel.OtherParentalConsentRequired,
        IsSpecialConsentRequired: selectModel.IsSpecialConsentRequired,
        OtherSpecialConsentRequired: selectModel.OtherSpecialConsentRequired,
        GDPRConsentRequired: selectModel.GDPRConsentRequired,
        GDPRParentalConsentRequired: selectModel.GDPRParentalConsentRequired,
        GDPRSpecialConsentRequired: selectModel.GDPRSpecialConsentRequired,
        IsOwnerSubmit: (selectModel.IsOwnerSubmit == undefined || selectModel.IsOwnerSubmit == null || selectModel.IsOwnerSubmit == '' ? '' : selectModel.IsOwnerSubmit),
        IsTeamAttest: (selectModel.IsTeamAttest == undefined || selectModel.IsTeamAttest == null || selectModel.IsTeamAttest == '' ? '' : selectModel.IsTeamAttest),
      }
      this._http.post(this.myAppUrl + 'AddGDPROverview', array).subscribe(
        data => {
          if (data.json() == "success") {
            //this.selectModel = {};
            this.toastr.success('Record added successfully', "");
            this.getallgriddata();
            this.editMode = false;
            this.showAddwindow = false;
          }
          else {
            this.toastr.warning(data.json(), "");;
          }
          this.IsAddDisabled = false;
        },
        error => {
          console.log(JSON.stringify(error.json()));
        }
      )
    }

  };
  getdynamicobject(userId, type) {
    for (var i = 0; i < this[type].length; i++) {
      if (this[type][i].Id == userId) {
        return this[type][i];
      }
    }
  };
  edit(obj) {
    this.appService.getAllGDPROverview(obj.Id)
      .subscribe((exdata) => {
        this.selectModel = exdata[0];
        this.editMode = true;
        this.IsAddDisabled = false;
        this.showAddwindow = true;
        this.IsExpanded = false;
        this.IsAllowEdit = false;
        this.IsAdminExpanded = false;
        this.showAddReviewComment = false;
        this.DataBreach = [];
        this.Prevvalue = Object.assign({}, this.selectModel);
        this.selectModel.WhoseData = this.getdynamicobject(this.selectModel.WhoseDataId, "WhoseDataList");
        this.selectModel.UniqDataReference = this.getdynamicobject(this.selectModel.UniqDataReferenceId, "UniqDataReferenceList");

        this.bindDataController(this.UserList);
        this.assignDataController(this.selectModel.DataControllerIdList.split(','));

        this.bindSupervisoryAuthority(this.UserList);
        this.assignSupervisoryAuthority(this.selectModel.SupervisoryAuthorityIdList.split(','));

        this.bindjointcontrollerdata(this.UserList);
        this.assignJointController(this.selectModel.JointControllerIdList.split(','));

        this.binddataprotectionofficerdata(this.UserList);
        this.assignDataProtectionOfficer(this.selectModel.DataProtectionOfficerIdList.split(','));

        this.bindWhenObtainData(this.WhenObtainDataList);
        this.assignWhenObtainData(this.selectModel.WhenObtainDataIdList.split(','));

        this.bindWhatData(this.WhatDataList);
        this.assignWhatData(this.selectModel.WhatDataIdList.split(','));

        this.bindWhyCollectingData(this.WhyCollectingDataList);
        this.assignWhyCollectingData(this.selectModel.WhyCollectingDataIdList.split(','));

        this.bindHowObtainConsent(this.HowObtainConsentList);
        this.assignHowObtainConsent(this.selectModel.HowObtainConsentIdList.split(','));

        this.bindWhereDataStored(this.WhereDataStoredList);
        this.assignWhereDataStored(this.selectModel.WhereDataStoredIdList.split(','));

        this.bindWhatDataCollect(this.WhatDataCollectList);
        this.assignWhatDataCollect(this.selectModel.WhatDataCollectIdList.split(','));

        this.bindWhoAccessData(this.WhoAccessDataList);
        this.assignWhoAccessData(this.selectModel.WhoAccessDataIdList.split(','));

        this.bindImpactedBusinessProcesses(this.ImpactedBusinessProcessesList);
        this.assignImpactedBusinessProcesses(this.selectModel.ImpactedBusinessProcessesIdList.split(','));
        this.DataBreachList = Object.assign([], this.AllDBList);
        var bl = this.selectModel.DBList.split(',');
        if (bl != null && bl.length > 0) {
          for (var i = 0; i < bl.length; i++) {
            if (bl[i] != null && bl[i] != '') {
              var item = this.DataBreachList.filter(o => o.Id == bl[i])[0];
              if (item != undefined && item != null && item != '') {
                this.DataBreach.push(item);
                this.DataBreachList.splice(this.DataBreachList.findIndex(a => a.Id === item.Id), 1)
              }
            }
          }
        }
        this.GetAllSIApprovals();
        this.GetAllAdditionalApprovals();
        this.GetAllComments();
        this.GetAllLogInfo(this.selectModel.Id);

      }, error => console.log(error))
  }
  calculaterisk(obj) {
    if (obj == 1) {
      var rl = parseInt((this.selectModel.RiskLevel == undefined || this.selectModel.RiskLevel == null || this.selectModel.RiskLevel == '') ? '0' :
        this.selectModel.RiskLevel == '1' ? '4' : this.selectModel.RiskLevel == '2' ? '3' : this.selectModel.RiskLevel == '3' ? '2' : '1');
      var dtl = parseInt((this.selectModel.DataThreatLevel == undefined || this.selectModel.DataThreatLevel == null || this.selectModel.DataThreatLevel == '') ? '0' :
        this.selectModel.DataThreatLevel == '1' ? '5' : this.selectModel.DataThreatLevel == '2' ? '4' : this.selectModel.DataThreatLevel == '3' ? '3' :
          this.selectModel.DataThreatLevel == '4' ? '2' : '1');
      var val = rl * dtl;
      this.selectModel.RiskRImpact = ((val >= 10) ? '1' : (val >= 8 && val < 10) ? '2' : (val >= 6 && val < 8) ? '3' : (val >= 1 && val < 6) ? '4' : '');
    }
    else if (obj == 2) {
      var rl = parseInt((this.selectModel.RRiskLevel == undefined || this.selectModel.RRiskLevel == null || this.selectModel.RRiskLevel == '') ? '0' :
        this.selectModel.RRiskLevel == '1' ? '4' : this.selectModel.RRiskLevel == '2' ? '3' : this.selectModel.RRiskLevel == '3' ? '2' : '1');
      var dtl = parseInt((this.selectModel.RDataThreatLevel == undefined || this.selectModel.RDataThreatLevel == null || this.selectModel.RDataThreatLevel == '') ? '0' :
        this.selectModel.RDataThreatLevel == '1' ? '5' : this.selectModel.RDataThreatLevel == '2' ? '4' : this.selectModel.RDataThreatLevel == '3' ? '3' :
          this.selectModel.RDataThreatLevel == '4' ? '2' : '1');
      var val = rl * dtl;
      this.selectModel.RRiskRImpact = ((val >= 10) ? '1' : (val >= 8 && val < 10) ? '2' : (val >= 6 && val < 8) ? '3' : (val >= 1 && val < 6) ? '4' : '');
    }
    else {
      var rl = parseInt((this.selectModel.IRiskLevel == undefined || this.selectModel.IRiskLevel == null || this.selectModel.IRiskLevel == '') ? '0' :
        this.selectModel.IRiskLevel == '1' ? '4' : this.selectModel.IRiskLevel == '2' ? '3' : this.selectModel.IRiskLevel == '3' ? '2' : '1');
      var dtl = parseInt((this.selectModel.IDataThreatLevel == undefined || this.selectModel.IDataThreatLevel == null || this.selectModel.IDataThreatLevel == '') ? '0' :
        this.selectModel.IDataThreatLevel == '1' ? '5' : this.selectModel.IDataThreatLevel == '2' ? '4' : this.selectModel.IDataThreatLevel == '3' ? '3' :
          this.selectModel.IDataThreatLevel == '4' ? '2' : '1');
      var val = rl * dtl;
      this.selectModel.IRiskRImpact = ((val >= 10) ? '1' : (val >= 8 && val < 10) ? '2' : (val >= 6 && val < 8) ? '3' : (val >= 1 && val < 6) ? '4' : '');
    }
  }
  formatDate(data) {
    if (data != undefined && data != '' && data != null) {
      var tcdate = new Date(Date.parse(data));
      return this.getFormattedDate(tcdate);
    }
    else {
      return '';
    }
  }
  formatDates(data) {
    if (data != undefined && data != '' && data != null) {
      var tcdate = new Date(Date.parse(data));
      return this.getFormattedDate(tcdate);
    }
    else {
      return '';
    }
  }
  toDate(dateStr) {
    var parts = dateStr.split("/")
    return new Date(parts[2], parts[1] - 1, parts[0])
  }
  getFormattedDate(d) {
    var date = d;
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return day + '/' + month + '/' + year;
  }
  errorHandler(error: Response) {
    console.log(error);
    return Observable.throw(error);
  }
  UpdateData(selectModel) {
    if (selectModel.WhoseData == undefined || selectModel.WhoseData == null || selectModel.WhoseData == "") {
      this.toastr.warning('Please select "Whose Data Do You Collect?"', "");
    }
    else if (selectModel.UniqDataReference == undefined || selectModel.UniqDataReference == null || selectModel.UniqDataReference == "") {
      this.toastr.warning('Please select "Data Collection Reference"', "");
    }
    else {
      var cmnt = this.CommentsData;
      var DataBreach = this.DataBreach; var BreachList = '', DBNameList = '';
      //var GDPRProcessFlow = this.GDPRProcessFlow;
      var respidlist = '', accidlist = '', infidlist = '', considlist = '', regidlist = '',
        DataControllerIdList = '', SupervisoryAuthorityIdList = '', JointControllerIdList = '',
        DataProtectionOfficerIdList = '';
      var respnamelist = '', accnamelist = '', infnamelist = '', consnamelist = '', regnamelist = '',
        DataControllerNameList = '', SupervisoryAuthorityNameList = '', JointControllerNameList = '',
        DataProtectionOfficerNameList = '';
      if (selectModel.DataControllerselected != undefined && selectModel.DataControllerselected != null && selectModel.DataControllerselected.length > 0) {
        for (var i = 0; i < selectModel.DataControllerselected.length; i++) {
          if (i == selectModel.DataControllerselected.length - 1) {
            DataControllerIdList += selectModel.DataControllerselected[i].item_id;
            DataControllerNameList += selectModel.DataControllerselected[i].item_text;
          }
          else {
            DataControllerIdList += selectModel.DataControllerselected[i].item_id + ',';
            DataControllerNameList += selectModel.DataControllerselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.SupervisoryAuthorityselected != undefined && selectModel.SupervisoryAuthorityselected != null && selectModel.SupervisoryAuthorityselected.length > 0) {
        for (var i = 0; i < selectModel.SupervisoryAuthorityselected.length; i++) {
          if (i == selectModel.SupervisoryAuthorityselected.length - 1) {
            SupervisoryAuthorityIdList += selectModel.SupervisoryAuthorityselected[i].item_id;
            SupervisoryAuthorityNameList += selectModel.SupervisoryAuthorityselected[i].item_text;
          }
          else {
            SupervisoryAuthorityIdList += selectModel.SupervisoryAuthorityselected[i].item_id + ',';
            SupervisoryAuthorityNameList += selectModel.SupervisoryAuthorityselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.JointControllerselected != undefined && selectModel.JointControllerselected != null && selectModel.JointControllerselected.length > 0) {
        for (var i = 0; i < selectModel.JointControllerselected.length; i++) {
          if (i == selectModel.JointControllerselected.length - 1) {
            JointControllerIdList += selectModel.JointControllerselected[i].item_id;
            JointControllerNameList += selectModel.JointControllerselected[i].item_text;
          }
          else {
            JointControllerIdList += selectModel.JointControllerselected[i].item_id + ',';
            JointControllerNameList += selectModel.JointControllerselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.DataProtectionOfficerselected != undefined && selectModel.DataProtectionOfficerselected != null && selectModel.DataProtectionOfficerselected.length > 0) {
        for (var i = 0; i < selectModel.DataProtectionOfficerselected.length; i++) {
          if (i == selectModel.DataProtectionOfficerselected.length - 1) {
            DataProtectionOfficerIdList += selectModel.DataProtectionOfficerselected[i].item_id;
            DataProtectionOfficerNameList += selectModel.DataProtectionOfficerselected[i].item_text;
          }
          else {
            DataProtectionOfficerIdList += selectModel.DataProtectionOfficerselected[i].item_id + ',';
            DataProtectionOfficerNameList += selectModel.DataProtectionOfficerselected[i].item_text + ',';
          }
        }
      }

      // GDPR Overview Basic Multi Selections
      var WhenObtainDataIdList = '', WhatDataIdList = '', WhyCollectingDataIdList = '', HowObtainConsentIdList = '',
        WhereDataStoredIdList = '', WhatDataCollectIdList = '', WhoAccessDataIdList = '', ImpactedBusinessProcessesIdList = '';
      var WhenObtainDataNameList = '', WhatDataNameList = '', WhyCollectingDataNameList = '', HowObtainConsentNameList = '',
        WhereDataStoredNameList = '', WhatDataCollectNameList = '', WhoAccessDataNameList = '', ImpactedBusinessProcessesNameList = '';
      if (selectModel.WhenObtainDataselected != undefined && selectModel.WhenObtainDataselected != null && selectModel.WhenObtainDataselected.length > 0) {
        for (var i = 0; i < selectModel.WhenObtainDataselected.length; i++) {
          if (i == selectModel.WhenObtainDataselected.length - 1) {
            WhenObtainDataIdList += selectModel.WhenObtainDataselected[i].item_id;
            WhenObtainDataNameList += selectModel.WhenObtainDataselected[i].item_text;
          }
          else {
            WhenObtainDataIdList += selectModel.WhenObtainDataselected[i].item_id + ',';
            WhenObtainDataNameList += selectModel.WhenObtainDataselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.WhatDataselected != undefined && selectModel.WhatDataselected != null && selectModel.WhatDataselected.length > 0) {
        for (var i = 0; i < selectModel.WhatDataselected.length; i++) {
          if (i == selectModel.WhatDataselected.length - 1) {
            WhatDataIdList += selectModel.WhatDataselected[i].item_id;
            WhatDataNameList += selectModel.WhatDataselected[i].item_text;
          }
          else {
            WhatDataIdList += selectModel.WhatDataselected[i].item_id + ',';
            WhatDataNameList += selectModel.WhatDataselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.WhyCollectingDataselected != undefined && selectModel.WhyCollectingDataselected != null && selectModel.WhyCollectingDataselected.length > 0) {
        for (var i = 0; i < selectModel.WhyCollectingDataselected.length; i++) {
          if (i == selectModel.WhyCollectingDataselected.length - 1) {
            WhyCollectingDataIdList += selectModel.WhyCollectingDataselected[i].item_id;
            WhyCollectingDataNameList += selectModel.WhyCollectingDataselected[i].item_text;
          }
          else {
            WhyCollectingDataIdList += selectModel.WhyCollectingDataselected[i].item_id + ',';
            WhyCollectingDataNameList += selectModel.WhyCollectingDataselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.HowObtainConsentselected != undefined && selectModel.HowObtainConsentselected != null && selectModel.HowObtainConsentselected.length > 0) {
        for (var i = 0; i < selectModel.HowObtainConsentselected.length; i++) {
          if (i == selectModel.HowObtainConsentselected.length - 1) {
            HowObtainConsentIdList += selectModel.HowObtainConsentselected[i].item_id;
            HowObtainConsentNameList += selectModel.HowObtainConsentselected[i].item_text;
          }
          else {
            HowObtainConsentIdList += selectModel.HowObtainConsentselected[i].item_id + ',';
            HowObtainConsentNameList += selectModel.HowObtainConsentselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.WhereDataStoredselected != undefined && selectModel.WhereDataStoredselected != null && selectModel.WhereDataStoredselected.length > 0) {
        for (var i = 0; i < selectModel.WhereDataStoredselected.length; i++) {
          if (i == selectModel.WhereDataStoredselected.length - 1) {
            WhereDataStoredIdList += selectModel.WhereDataStoredselected[i].item_id;
            WhereDataStoredNameList += selectModel.WhereDataStoredselected[i].item_text;
          }
          else {
            WhereDataStoredIdList += selectModel.WhereDataStoredselected[i].item_id + ',';
            WhereDataStoredNameList += selectModel.WhereDataStoredselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.WhatDataCollectselected != undefined && selectModel.WhatDataCollectselected != null && selectModel.WhatDataCollectselected.length > 0) {
        for (var i = 0; i < selectModel.WhatDataCollectselected.length; i++) {
          if (i == selectModel.WhatDataCollectselected.length - 1) {
            WhatDataCollectIdList += selectModel.WhatDataCollectselected[i].item_id;
            WhatDataCollectNameList += selectModel.WhatDataCollectselected[i].item_text;
          }
          else {
            WhatDataCollectIdList += selectModel.WhatDataCollectselected[i].item_id + ',';
            WhatDataCollectNameList += selectModel.WhatDataCollectselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.WhoAccessDataselected != undefined && selectModel.WhoAccessDataselected != null && selectModel.WhoAccessDataselected.length > 0) {
        for (var i = 0; i < selectModel.WhoAccessDataselected.length; i++) {
          if (i == selectModel.WhoAccessDataselected.length - 1) {
            WhoAccessDataIdList += selectModel.WhoAccessDataselected[i].item_id;
            WhoAccessDataNameList += selectModel.WhoAccessDataselected[i].item_text;
          }
          else {
            WhoAccessDataIdList += selectModel.WhoAccessDataselected[i].item_id + ',';
            WhoAccessDataNameList += selectModel.WhoAccessDataselected[i].item_text + ',';
          }
        }
      }
      if (selectModel.ImpactedBusinessProcessesselected != undefined && selectModel.ImpactedBusinessProcessesselected != null && selectModel.ImpactedBusinessProcessesselected.length > 0) {
        for (var i = 0; i < selectModel.ImpactedBusinessProcessesselected.length; i++) {
          if (i == selectModel.ImpactedBusinessProcessesselected.length - 1) {
            ImpactedBusinessProcessesIdList += selectModel.ImpactedBusinessProcessesselected[i].item_id;
            ImpactedBusinessProcessesNameList += selectModel.ImpactedBusinessProcessesselected[i].item_text;
          }
          else {
            ImpactedBusinessProcessesIdList += selectModel.ImpactedBusinessProcessesselected[i].item_id + ',';
            ImpactedBusinessProcessesNameList += selectModel.ImpactedBusinessProcessesselected[i].item_text + ',';
          }
        }
      }
      for (var i = 0; i < this.DataBreach.length; i++) {
        if (i == this.DataBreach.length - 1) {
          BreachList += this.DataBreach[i].Id;
          DBNameList += this.DataBreach[i].BreachTitle;
        }
        else {
          BreachList += this.DataBreach[i].Id + ',';
          DBNameList += this.DataBreach[i].BreachTitle + ',';
        }
      }
      var input = {
        Id: selectModel.Id,
        AssetComments: JSON.stringify(cmnt),
        DBList: BreachList,
        DBNameList: DBNameList,
        WhoseDataId: (selectModel.WhoseData == undefined || selectModel.WhoseData == null) ? '' : selectModel.WhoseData.Id,
        WhoseData: (selectModel.WhoseData == undefined || selectModel.WhoseData == null) ? '' : selectModel.WhoseData.WhoseData,
        WhatDataId: (selectModel.WhatData == undefined || selectModel.WhatData == null) ? '' : selectModel.WhatData.Id,
        WhatData: (selectModel.WhatData == undefined || selectModel.WhatData == null) ? '' : selectModel.WhatData.WhatData,
        WhereDataStoredId: (selectModel.WhereDataStored == undefined || selectModel.WhereDataStored == null) ? '' : selectModel.WhereDataStored.Id,
        WhereDataStored: (selectModel.WhereDataStored == undefined || selectModel.WhereDataStored == null) ? '' : selectModel.WhereDataStored.WhereDataStored,
        ImpactedBusinessProcessesId: (selectModel.ImpactedBusinessProcesses == undefined || selectModel.ImpactedBusinessProcesses == null) ? '' : selectModel.ImpactedBusinessProcesses.Id,
        UniqDataReferenceId: (selectModel.UniqDataReference == undefined || selectModel.UniqDataReference == null) ? '' : selectModel.UniqDataReference.Id,
        UniqDataReference: (selectModel.UniqDataReference == undefined || selectModel.UniqDataReference == null) ? '' : selectModel.UniqDataReference.UniqDataReference,
        WhyCollectingDataId: (selectModel.WhyCollectingData == undefined || selectModel.WhyCollectingData == null) ? '' : selectModel.WhyCollectingData.Id,
        WhatDataCollectId: (selectModel.WhatDataCollect == undefined || selectModel.WhatDataCollect == null) ? '' : selectModel.WhatDataCollect.Id,
        WhenObtainDataId: (selectModel.WhenObtainData == undefined || selectModel.WhenObtainData == null) ? '' : selectModel.WhenObtainData.Id,
        HowObtainConsentId: (selectModel.HowObtainConsent == undefined || selectModel.HowObtainConsent == null) ? '' : selectModel.HowObtainConsent.Id,
        WhoAccessDataId: (selectModel.WhoAccessData == undefined || selectModel.WhoAccessData == null) ? '' : selectModel.WhoAccessData.Id,
        WhoAccessData: (selectModel.WhoAccessData == undefined || selectModel.WhoAccessData == null) ? '' : selectModel.WhoAccessData.WhoAccessData,
        HardwareCode: selectModel.HardwareCode,
        VersionAttributes: selectModel.VersionAttributes,

        WhenObtainDataIdList: WhenObtainDataIdList,
        WhenObtainDataNameList: WhenObtainDataNameList,
        WhatDataIdList: WhatDataIdList,
        WhatDataNameList: WhatDataNameList,
        WhyCollectingDataIdList: WhyCollectingDataIdList,
        WhyCollectingDataNameList: WhyCollectingDataNameList,
        HowObtainConsentIdList: HowObtainConsentIdList,
        HowObtainConsentNameList: HowObtainConsentNameList,
        WhereDataStoredIdList: WhereDataStoredIdList,
        WhereDataStoredNameList: WhereDataStoredNameList,
        WhatDataCollectIdList: WhatDataCollectIdList,
        WhatDataCollectNameList: WhatDataCollectNameList,
        WhoAccessDataIdList: WhoAccessDataIdList,
        WhoAccessDataNameList: WhoAccessDataNameList,
        ImpactedBusinessProcessesIdList: ImpactedBusinessProcessesIdList,
        ImpactedBusinessProcessesNameList: ImpactedBusinessProcessesNameList,

        InstallationDate: selectModel.InstallationDate,
        DecomissionedDate: selectModel.DecomissionedDate,
        OrgOwnedId: (selectModel.OrgOwnedId == undefined || selectModel.OrgOwnedId == null) ? '' : selectModel.OrgOwnedId,
        ApplicationId: (selectModel.ApplicationId == undefined || selectModel.ApplicationId == null) ? '' : selectModel.ApplicationId.Id,
        TeamId: (selectModel.Team == undefined || selectModel.Team == null) ? '' : selectModel.Team.Id,
        BusinessProcessId: (selectModel.BusinessProcess == undefined || selectModel.BusinessProcess == null) ? '' : selectModel.BusinessProcess.Id,
        BusinessSubProcessId: (selectModel.BusinessSubProcess == undefined || selectModel.BusinessSubProcess == null) ? '' : selectModel.BusinessSubProcess.Id,
        VendorName: selectModel.VendorName,
        VendorAddress: selectModel.VendorAddress,
        VendorContact: selectModel.VendorContact,
        VendorPhoneNo: selectModel.VendorPhoneNo,
        LRSReference: selectModel.LRSReference,
        LPFrom: selectModel.LPFrom,
        LPTo: selectModel.LPTo,
        LEDate: selectModel.LEDate,
        SPFrom: selectModel.SPFrom,
        SPTo: selectModel.SPTo,
        SEDate: selectModel.SEDate,
        AssetHandlingRules: selectModel.AssetHandlingRules,
        AHRC: selectModel.AHRC,
        ROAHR: selectModel.ROAHR,
        RiskRImpact: selectModel.RiskRImpact,
        RiskRImpactValue: !Boolean(selectModel.RiskRImpact) ? '' : selectModel.RiskRImpact == '1' ? 'Very High' : selectModel.RiskRImpact == '2' ? 'High' : selectModel.RiskRImpact == '3' ? 'Medium' : selectModel.RiskRImpact == '4' ? 'Low' : '',
        RiskLevel: selectModel.RiskLevel,
        RiskLevelValue: !Boolean(selectModel.RiskLevel) ? '' : selectModel.RiskLevel == '1' ? 'Catastrophic' : selectModel.RiskLevel == '2' ? 'Critical' : selectModel.RiskLevel == '3' ? 'Marginal' : selectModel.RiskLevel == '4' ? 'Negligible' : '',
        DataThreatLevel: selectModel.DataThreatLevel,
        DataThreatLevelValue: !Boolean(selectModel.DataThreatLevel) ? '' : selectModel.DataThreatLevel == '1' ? 'Frequent' : selectModel.DataThreatLevel == '2' ? 'Probable' : selectModel.DataThreatLevel == '3' ? 'Occasional' : selectModel.DataThreatLevel == '4' ? 'Remote' : selectModel.DataThreatLevel == '5' ? 'Improbable' : '',
        RRiskRImpact: selectModel.RRiskRImpact,
        RRiskRImpactValue: !Boolean(selectModel.RRiskRImpact) ? '' : selectModel.RRiskRImpact == '1' ? 'Very High' : selectModel.RRiskRImpact == '2' ? 'High' : selectModel.RRiskRImpact == '3' ? 'Medium' : selectModel.RRiskRImpact == '4' ? 'Low' : '',
        RRiskLevel: selectModel.RRiskLevel,
        RRiskLevelValue: !Boolean(selectModel.RRiskLevel) ? '' : selectModel.RRiskLevel == '1' ? 'Catastrophic' : selectModel.RRiskLevel == '2' ? 'Critical' : selectModel.RRiskLevel == '3' ? 'Marginal' : selectModel.RRiskLevel == '4' ? 'Negligible' : '',
        RDataThreatLevel: selectModel.RDataThreatLevel,
        RDataThreatLevelValue: !Boolean(selectModel.RDataThreatLevel) ? '' : selectModel.RDataThreatLevel == '1' ? 'Frequent' : selectModel.RDataThreatLevel == '2' ? 'Probable' : selectModel.RDataThreatLevel == '3' ? 'Occasional' : selectModel.RDataThreatLevel == '4' ? 'Remote' : selectModel.RDataThreatLevel == '5' ? 'Improbable' : '',
        IRiskRImpact: selectModel.IRiskRImpact,
        IRiskRImpactValue: !Boolean(selectModel.IRiskRImpact) ? '' : selectModel.IRiskRImpact == '1' ? 'Very High' : selectModel.IRiskRImpact == '2' ? 'High' : selectModel.IRiskRImpact == '3' ? 'Medium' : selectModel.IRiskRImpact == '4' ? 'Low' : '',
        IRiskLevel: selectModel.IRiskLevel,
        IRiskLevelValue: !Boolean(selectModel.IRiskLevel) ? '' : selectModel.IRiskLevel == '1' ? 'Catastrophic' : selectModel.IRiskLevel == '2' ? 'Critical' : selectModel.IRiskLevel == '3' ? 'Marginal' : selectModel.IRiskLevel == '4' ? 'Negligible' : '',
        IDataThreatLevel: selectModel.IDataThreatLevel,
        IDataThreatLevelValue: !Boolean(selectModel.IDataThreatLevel) ? '' : selectModel.IDataThreatLevel == '1' ? 'Frequent' : selectModel.IDataThreatLevel == '2' ? 'Probable' : selectModel.IDataThreatLevel == '3' ? 'Occasional' : selectModel.IDataThreatLevel == '4' ? 'Remote' : selectModel.IDataThreatLevel == '5' ? 'Improbable' : '',
        RMDateModified: selectModel.RMDateModified,
        Integrity: selectModel.Integrity,
        Confidentiality: selectModel.Confidentiality,
        Availability: selectModel.Availability,
        BackUpAssetID: selectModel.BackUpAssetID,
        RTObjective: selectModel.RTObjective,
        RPObjective: selectModel.RPObjective,
        DisasterRecoveryTestId: (selectModel.DisasterRecoveryTest == undefined || selectModel.DisasterRecoveryTest == null) ? '' : selectModel.DisasterRecoveryTest.Id,
        BackUpRDate: selectModel.BackUpRDate,
        ADHS: selectModel.ADHS,
        ADC: selectModel.ADC,
        ADR: selectModel.ADR,
        AssetOwnerApproval: selectModel.AssetOwnerApproval,
        AMTR: selectModel.AMTR,
        AMTRReturned: selectModel.AMTRReturned,
        ARACI: selectModel.ARACI,
        PatchingFrequencyId: (selectModel.PatchingFrequency == undefined || selectModel.PatchingFrequency == null) ? '' : selectModel.PatchingFrequency.Id,
        LPUDate: selectModel.LPUDate,
        NPDDate: selectModel.NPDDate,
        ApplicationNameId: (selectModel.ApplicationName == undefined || selectModel.ApplicationName == null) ? '' : selectModel.ApplicationName.Id,
        ApplicationSponsorerId: (selectModel.ApplicationSponsorer == undefined || selectModel.ApplicationSponsorer == null) ? '' : selectModel.ApplicationSponsorer.Id,
        ApplicationOwnerId: (selectModel.ApplicationOwner == undefined || selectModel.ApplicationOwner == null) ? '' : selectModel.ApplicationOwner.Id,
        ApplicationCode: selectModel.ApplicationCode,
        SoftwareHandlingRules: selectModel.SoftwareHandlingRules,
        SHRC: selectModel.SHRC,
        HAServer: selectModel.HAServer,
        HDBServer: selectModel.HDBServer,
        DBName: selectModel.DBName,
        GDPRRelevantId: (selectModel.GDPRRelevant == undefined || selectModel.GDPRRelevant == null) ? '' : selectModel.GDPRRelevant.Id,
        OtherRegulations: selectModel.OtherRegulations,
        DatabaseNameId: (selectModel.DatabaseName == undefined || selectModel.DatabaseName == null) ? '' : selectModel.DatabaseName.Id,
        DatabaseVersion: selectModel.DatabaseVersion,
        DatabaseOwnerId: (selectModel.DatabaseOwner == undefined || selectModel.DatabaseOwner == null) ? '' : selectModel.DatabaseOwner.Id,
        DataTypeId: (selectModel.DataType == undefined || selectModel.DataType == null) ? '' : selectModel.DataType.Id,
        DataHandlingRules: selectModel.DataHandlingRules,
        DHRCompliance: selectModel.DHRCompliance,
        DataCategoryId: (selectModel.DataCategory == undefined || selectModel.DataCategory == null) ? '' : selectModel.DataCategory.Id,
        StorageTypeId: (selectModel.StorageType == undefined || selectModel.StorageType == null) ? '' : selectModel.StorageType.Id,
        DataRetentionPeriodId: (selectModel.DataRetentionPeriod == undefined || selectModel.DataRetentionPeriod == null) ? '' : selectModel.DataRetentionPeriod.Id,
        DataClassificationId: (selectModel.DataClassification == undefined || selectModel.DataClassification == null) ? '' : selectModel.DataClassification.Id,
        SupervisoryAuthorityId: (selectModel.SupervisoryAuthority == undefined || selectModel.SupervisoryAuthority == null) ? '' : selectModel.SupervisoryAuthority.Id,
        PrivacyImpactAssessmentsId: (selectModel.PrivacyImpactAssessments == undefined || selectModel.PrivacyImpactAssessments == null) ? '' : selectModel.PrivacyImpactAssessments.Id,
        SupervisoryAuthorityApprovalId: (selectModel.SupervisoryAuthorityApproval == undefined || selectModel.SupervisoryAuthorityApproval == null) ? '' : selectModel.SupervisoryAuthorityApproval.Id,
        DemostrateConsentId: (selectModel.DemostrateConsent == undefined || selectModel.DemostrateConsent == null) ? '' : selectModel.DemostrateConsent.Id,
        ParentalConsentId: (selectModel.ParentalConsent == undefined || selectModel.ParentalConsent == null) ? '' : selectModel.ParentalConsent.Id,
        CPFrom: selectModel.CPFrom,
        CPTo: selectModel.CPTo,
        CPEDate: selectModel.CPEDate,
        DataProcessorsId: (selectModel.DataProcessors == undefined || selectModel.DataProcessors == null) ? '' : selectModel.DataProcessors.Id,
        DataVisibilityHasId: (selectModel.DataVisibilityHas == undefined || selectModel.DataVisibilityHas == null) ? '' : selectModel.DataVisibilityHas.Id,
        DataVisibilityNeedId: (selectModel.DataVisibilityNeed == undefined || selectModel.DataVisibilityNeed == null) ? '' : selectModel.DataVisibilityNeed.Id,
        DataControllerId: (selectModel.DataController == undefined || selectModel.DataController == null) ? '' : selectModel.DataController.Id,
        DSC: selectModel.DSC,
        DCR: selectModel.DCR,
        ReCertDate: (selectModel.ReCertDate == undefined || selectModel.ReCertDate == null) ? '' : selectModel.ReCertDate,
        OtherRegulationsId: (selectModel.OtherRegulationsId == undefined || selectModel.OtherRegulationsId == null) ? '' : selectModel.OtherRegulationsId.Id,
        VendorNameId: (selectModel.VendorNameId == undefined || selectModel.VendorNameId == null) ? '' : selectModel.VendorNameId.Id,
        BackupStatusId: (selectModel.BackupStatus == undefined || selectModel.BackupStatus == null) ? '' : selectModel.BackupStatus.Id,
        IsCPE: (selectModel.IsCPE == undefined || selectModel.IsCPE == null) ? '' : selectModel.IsCPE,
        ResponsibleIdList: respidlist,
        ResponsibleNameList: respnamelist,
        AccountableIdList: accidlist,
        AccountableNameList: accnamelist,
        ConsultedIdList: considlist,
        ConsultedNameList: consnamelist,
        InformedIdList: infidlist,
        InformedNameList: infnamelist,
        OtherRegulationsIdList: regidlist,
        OtherRegulationsNameList: regnamelist,
        DataControllerIdList: DataControllerIdList,
        DataControllerNameList: DataControllerNameList,
        SupervisoryAuthorityIdList: SupervisoryAuthorityIdList,
        SupervisoryAuthorityNameList: SupervisoryAuthorityNameList,
        JointControllerIdList: JointControllerIdList,
        JointControllerNameList: JointControllerNameList,
        DataProtectionOfficerIdList: DataProtectionOfficerIdList,
        DataProtectionOfficerNameList: DataProtectionOfficerNameList,
        StorageLimitation: selectModel.StorageLimitation,
        PurposeLimitation: selectModel.PurposeLimitation,
        PurposeOfProcessing: selectModel.PurposeOfProcessing,
        IsConsentRequired: selectModel.IsConsentRequired,
        OtherConsentRequired: selectModel.OtherConsentRequired,
        IsParentalConsentRequired: selectModel.IsParentalConsentRequired,
        OtherParentalConsentRequired: selectModel.OtherParentalConsentRequired,
        IsSpecialConsentRequired: selectModel.IsSpecialConsentRequired,
        OtherSpecialConsentRequired: selectModel.OtherSpecialConsentRequired,
        GDPRConsentRequired: selectModel.GDPRConsentRequired,
        GDPRParentalConsentRequired: selectModel.GDPRParentalConsentRequired,
        GDPRSpecialConsentRequired: selectModel.GDPRSpecialConsentRequired,
        IsOwnerSubmit: selectModel.IsOwnerSubmit,
        IsTeamAttest: selectModel.IsTeamAttest,
        ProcessDocuments: [],
      }
      var array = [];
      array.push(input);
      array.push(this.Prevvalue);
      this.IsAddDisabled = true;
      this._http.post(this.myAppUrl + 'UpdateGDPROverviewManagement', array).subscribe(
        data => {
          if (data.json() == "success") {
            //this.selectModel = {};
            this.edit(this.selectModel);
            this.toastr.success('Record updated successfully', "");
            // this.getallgriddata();
            // this.editMode = false;
            // this.showAddwindow = false;
          }
          else {
            this.toastr.warning(data.json(), "");;
          }
          this.IsAddDisabled = false;
        },
        error => {
          console.log(JSON.stringify(error.json()));
        }
      )
    }
  };

  getFormattedDateTime(d) {
    var now = d;
    var year = now.getFullYear();
    var month = (now.getMonth() + 1).toString();
    var day = (now.getDate()).toString();
    var hour = (now.getHours()).toString();
    var minute = (now.getMinutes()).toString();
    var second = (now.getSeconds()).toString();
    if (month.toString().length == 1) {
      month = '0' + month;
    }
    if (day.toString().length == 1) {
      day = '0' + day;
    }
    if (hour.toString().length == 1) {
      hour = '0' + hour;
    }
    if (minute.toString().length == 1) {
      minute = '0' + minute;
    }
    if (second.toString().length == 1) {
      second = '0' + second;
    }
    var dateTime = day + '/' + month + '/' + year + ' ' + hour + ':' + minute;
    return dateTime;
  }

  ApprovalTriggerType: any;
  showApprovalModal(content, type) {
    this.ApprovalTriggerType = type;
    this.openCM(content);
  }

  IsAllApproved = true;
  SIApprovals: any;
  IsAllSubmitted: boolean;

  GetAllSIApprovals() {

    this.appService.GetAllDataApprovals(this.selectModel.Id)
      .subscribe((data) => {
        this.SIApprovals = data;
        var isAprv = data.filter(o => o.IsApproved != 'True').length;
        if (isAprv > 0) {
          this.IsAllApproved = false;
        }
        else {
          this.IsAllApproved = true;
        }
        var isAprv = data.filter(o => o.IsSubmitted == 'True').length;
        if (isAprv > 0 && isAprv == data.length) {
          this.IsAllSubmitted = true;
        }
        else {
          this.IsAllSubmitted = false;
        }
        for (var k = 0; k < this.SIApprovals.length; k++) {
          this.SIApprovals[k].Periods = this.IsNullOrEmty(this.SIApprovals[k].Periods) == '' ? '' : this.SIApprovals[k].Periods;
          if (this.SIApprovals[k].DueDate != undefined && this.SIApprovals[k].DueDate != '' && this.SIApprovals[k].DueDate != null) {
            var tcdate = new Date(Date.parse(this.SIApprovals[k].DueDate));
            this.SIApprovals[k].DueDate = this.getFormattedDate(tcdate);
          }

        }
        this.GetAllComments();
      }, error => console.log(error))
  }

  PACommentsList: any;
  IsCommentsOpen = false;
  GetAllComments() {
    this.appService.GetAllDataComments(this.selectModel.Id.toString())
      .subscribe((data) => {
        this.CommentsAllList = data;
        if (this.SIApprovals != null && this.SIApprovals.length > 0) {
          var lastApproval = this.SIApprovals[0].IsApproved;
          if (lastApproval == 'True') {
            this.PACommentsList = data.filter(o => o.Reference == this.SIApprovals.length + 1);
          }
          else {
            this.PACommentsList = data.filter(o => o.Reference == this.SIApprovals.length);
          }
        }
        else {
          this.PACommentsList = data.filter(o => o.Reference == this.SIApprovals.length);
        }
        this.PrevPACommentsList = Object.assign([], data);
        var IsOpen = this.PACommentsList.filter(o => o.CommentStatus == 'Open');
        if (IsOpen != null && IsOpen.length > 0) {
          this.IsCommentsOpen = true;
        }
        else {
          this.IsCommentsOpen = false;
        }
        this.GetAllLogInfo(this.selectModel.Id);
      }, error => console.log(error))
  }

  AdditionalApprovals: any; IsLastSubmitted = true;
  GetAllAdditionalApprovals() {
    this.AdditionalApprovals = []; this.IsAllAdditionalApproved = true;
    this.appService.GetAllDataAdditionalApprovals(this.selectModel.Id.toString())
      .subscribe((data) => {
        this.AdditionalApprovals = data;
        for (let obj of data) {
          this.loadApproversByTeam(obj);
        }
        var isAprv = data.filter(o => o.IsApproved != 'True').length;
        console.log(isAprv);
        if (isAprv > 0) {
          this.IsAllAdditionalApproved = false;
        }
        else {
          this.IsAllAdditionalApproved = true;
        }
      }, error => console.log(error))
  }

  AddApprovals() {
    var array = {
      Status: 'Approvals Required',
      RiskId: this.selectModel.Id,
    };
    this.IsAddDisabled = true;
    this._http.post(this.myAppUrl + 'AddDataApprovals', array).subscribe(
      data => {
        if (data.json() == "success") {
          this.GetAllSIApprovals();
          this.GetAllLogInfo(this.selectModel.Id);
          this.selectModel.ReviewStatus = 'Submission is in Progress';
          this.toastr.success('Review added successfully', "");
        }
        else {
          this.toastr.warning(data.json(), "");
        }
        this.IsAddDisabled = false;
      },
      error => {
        console.log(JSON.stringify(error.json()));
      }
    )
    this.modalService.dismissAll();
  }

  AddAdditionalApprovals() {
    var array = {
      Status: 'Approvals Required',
      RiskID: this.selectModel.Id.toString(),
      ApprovalId: this.SIApprovals[0].Id,
    };
    this._http.post(this.myAppUrl + 'AddDataAdditionalApprovals', array).subscribe(
      data => {
        if (data.json() == "success") {
          this.GetAllAdditionalApprovals();
          this.GetAllLogInfo(this.selectModel.Id);
          this.selectModel.ReviewStatus = 'Submission is in Progress';
          this.toastr.success('Additional Approver added successfully', "");
        }
        else {
          this.toastr.warning(data.json(), "");
        }
        this.IsAddDisabled = false;
      },
      error => {
        console.log(JSON.stringify(error.json()));
      }
    )
    this.modalService.dismissAll();
  }

  ApprovalType: any;
  setsignoff(obj, typ, content, triggerType) {
    this.ApprovalTriggerType = triggerType;
    if (triggerType == 'Generic') {
      if (obj.Periods == undefined || obj.Periods == null || obj.Periods == '') {
        this.toastr.warning('Please select "Period"', "");
      }
      else {
        this.appService.GetAllDataApprovals(this.selectModel.Id.toString())
          .subscribe((adata) => {
            if (adata.some(o => o.Periods === obj.Periods && o.Id != obj.Id) && typ == 3 && obj.IsReturned != 'True') {
              this.toastr.warning('"Period" already exists', "");
            }
            else {
              this.SingleSIApprovals = obj;
              this.ApprovalType = typ;
              this.openCM(content);
            }
          }, error => console.log(error))

      }
    }
    else {
      if (obj.TeamId == undefined || obj.TeamId == null || obj.TeamId == '') {
        this.toastr.warning('Please select "Team"', "");
      }
      else if (obj.ApproverId == undefined || obj.ApproverId == null || obj.ApproverId == '') {
        this.toastr.warning('Please select "Approver Name"', "");
      }
      else {
        this.SingleSIApprovals = obj;
        this.ApprovalType = typ;
        this.openCM(content);
      }
    }
  }

  AddYear(d, ny) {
    var date = d;
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return day + '/' + month + '/' + (parseInt(year) + ny);

  }
  setconfirminner() {
    this.modalService.dismissAll();
    if (this.ApprovalType == 1) {
      this.SingleSIApprovals.IsApproved = 'True';
      this.SingleSIApprovals.IsReturned = 'False';
      this.SingleSIApprovals.InternalStatus = 'Completed';
      this.selectModel.InternalStatus = 'Completed';
      this.selectModel.ReviewStatus = 'Approved';
      this.selectModel.NextReviewDate = this.AddYear(new Date(), 1);

    }
    else if (this.ApprovalType == 2) {
      this.SingleSIApprovals.IsApproved = 'False';
      this.SingleSIApprovals.IsSubmitted = 'False';
      this.SingleSIApprovals.IsReturned = 'True';
      this.SingleSIApprovals.InternalStatus = 'Returned';
      this.selectModel.InternalStatus = 'Returned';
      this.selectModel.ReviewStatus = 'More Info Required';
    }
    else {
      this.SingleSIApprovals.IsApproved = 'False';
      this.SingleSIApprovals.IsReturned = 'False';
      this.SingleSIApprovals.IsSubmitted = 'True';
      this.SingleSIApprovals.InternalStatus = 'Submitted';
      this.selectModel.InternalStatus = 'Submitted';
      this.selectModel.ReviewStatus = 'Approval In-progress';
    }
    if (Boolean(this.SingleSIApprovals.Periods)) {
      var list = this.ControlPeriodList.filter(o => o.Id == this.SingleSIApprovals.Periods);
      this.SingleSIApprovals.PeriodsValue = list[0].ControlPeriod;
    }
    this._http.post(this.myAppUrl + 'ModifyDataApprovals', this.SingleSIApprovals).subscribe(
      data => {
        if (data.json() == "success") {
          this.GetAllSIApprovals();
          this.GetAllLogInfo(this.selectModel.Id);
          if (this.ApprovalType == 1) {
            this.toastr.success('"Approved" status updated successfully', "");
          }
          else if (this.ApprovalType == 2) {
            this.toastr.success('"More Information Requested" status updated successfully', "");
          }
          else {
            this.toastr.success('"Data Subject" details submitted successfully', "");
          }
        }
        else {
          this.toastr.warning(data.json(), "");
        }
      },
      error => {
        console.log(JSON.stringify(error.json()));
      }
    )
  }

  updateApprovals(type) {
    if (type == 'Generic') {
      this.appService.GetAllDataApprovals(this.selectModel.Id.toString())
        .subscribe((data) => {
          var firstExElement = data.find(e => !!e);
          var newObj = this.SIApprovals.filter(o => o.Id == firstExElement.Id && o.Periods != firstExElement.Periods);
          if (newObj != null && newObj.length > 0) {
            var obj = newObj.find(e => !!e);
            if (!Boolean(obj.Periods)) {
              this.toastr.warning('Please select "Period"', "");
            }
            else if (data.some(o => o.Periods === obj.Periods)) {
              this.toastr.warning('"Period" already exists', "");
            }
            else {
              if (Boolean(obj.Periods)) {
                var list = this.ControlPeriodList.filter(o => o.Id == obj.Periods);
                obj.PeriodsValue = list[0].ControlPeriod;
              }
              this._http.post(this.myAppUrl + 'ModifyDataApprovals', obj).subscribe(
                mdata => {
                  if (mdata.json() == "success") {
                    this.GetAllLogInfo(this.selectModel.Id);
                    this.toastr.success('"Period" saved successfully', "");
                  }
                  else {
                    this.toastr.warning(mdata.json(), "");
                  }
                },
                error => {
                  console.log(JSON.stringify(error.json()));
                }
              )
            }
          }
          else {
            this.toastr.warning("No changes detected", "");
          }
        }, error => console.log(error))

    }
    else if (type == 'Additional') {
      this.appService.GetAllDataAdditionalApprovals(this.selectModel.Id.toString())
        .subscribe((data) => {
          var IsSomethingUpdated = 0;
          IsSomethingUpdated = this.compareAdditionalApprovals(data, IsSomethingUpdated);
          if (IsSomethingUpdated > 0) {
            setTimeout(() => {
              this.GetAllLogInfo(this.selectModel.Id);
            }, 100)
            this.toastr.success('"Additional Approval" details saved successfully', "");
          }
          else {
            this.toastr.warning("No changes detected", "");
          }
        }, error => console.log(error))
    }
  }
  CanApprove(obj) {
    if (!Boolean(obj.ApproverId)) {
      return false;
    }
    else {
      var userId = this.AllUsersList.filter(o => o.Id == obj.ApproverId)[0].userId;
      return (userId.toLowerCase() == this.userId.toLowerCase()) ? true : false;
    }
  }
  resetApprover(obj) {
    obj.ApproverId = '';
  }
  loadApproversByTeam(obj) {
    if (!Boolean(obj.TeamId)) {
      obj.ApproversList = [];
    }
    else {
      this.appService.GetUserByTeam(obj.TeamId)
        .subscribe((data) => {
          obj.ApproversList = data;
        }, error => console.log(error))
    }
    obj.TeamId = (obj.TeamId == '0' ? '' : obj.TeamId);
    obj.ApproverId = (obj.ApproverId == '0' ? '' : obj.ApproverId);
  }
  private compareAdditionalApprovals(data: any[], IsSomethingUpdated: number) {
    for (let ex of data) {
      var newObj = this.AdditionalApprovals.filter(o => o.Id == ex.Id && (o.TeamId != ex.TeamId || o.ApproverId != ex.ApproverId));
      if (newObj != null && newObj.length > 0) {
        IsSomethingUpdated += 1;
        var array = Object.assign({}, newObj.find(e => !!e));
        array.AlgorithmName = (this.selectModel.LTAStrategyName == undefined || this.selectModel.LTAStrategyName == null || this.selectModel.LTAStrategyName == '' ? '' : this.selectModel.LTAStrategyName.LTAStrategyName);
        array.AlgorithmStatus = (this.selectModel.StrategyStatus == undefined || this.selectModel.StrategyStatus == null || this.selectModel.StrategyStatus == '' ? '' : this.selectModel.StrategyStatus.StrategyStatus);
        array.ShortCode = this.selectModel.LTAShortCode;
        if (Boolean(array.TeamId)) {
          var list = this.TeamList.filter(o => o.Id == array.TeamId);
          array.Team = list[0].Team;
        }
        else {
          array.TeamId = null;
        }

        if (Boolean(array.ApproverId)) {
          var listApr = this.AllUsersList.filter(o => o.Id == array.ApproverId);
          array.ApproverName = listApr[0].UserName;
        }
        else {
          array.ApproverId = null;
        }

        this._http.post(this.myAppUrl + 'ModifyDataAdditionalApprovals', array).subscribe(
          mdata => {
            if (mdata.json() != "success") {
              this.toastr.warning(mdata.json(), "");
            }
          },
          error => {
            console.log(JSON.stringify(error.json()));
          }
        );
      }
    }
    return IsSomethingUpdated;
  }
  setconfirmAdditionalApproval() {
    this.modalService.dismissAll();
    if (this.ApprovalType == 1) {
      this.SingleSIApprovals.IsApproved = 'True';
      this.SingleSIApprovals.IsReturned = 'False';
      this.SingleSIApprovals.InternalStatus = 'Completed';
      this.selectModel.InternalStatus = 'Completed';
      this.selectModel.ReviewStatus = 'Approved';
      this.selectModel.NextReviewDate = this.AddYear(new Date(), 2);

    }
    else {
      this.SingleSIApprovals.IsApproved = 'False';
      this.SingleSIApprovals.IsSubmitted = 'False';
      this.SingleSIApprovals.IsReturned = 'True';
      this.SingleSIApprovals.InternalStatus = 'Returned';
      this.selectModel.InternalStatus = 'Returned';
      this.selectModel.ReviewStatus = 'More Info Required';
    }
    if (Boolean(this.SingleSIApprovals.TeamId)) {
      var list = this.TeamList.filter(o => o.Id == this.SingleSIApprovals.TeamId);
      this.SingleSIApprovals.Team = list[0].Team;
    }
    else {
      this.SingleSIApprovals.TeamId = null;
    }

    if (Boolean(this.SingleSIApprovals.ApproverId)) {
      var listApr = this.AllUsersList.filter(o => o.Id == this.SingleSIApprovals.ApproverId);
      this.SingleSIApprovals.ApproverName = listApr[0].UserName;
    }
    else {
      this.SingleSIApprovals.ApproverId = null;
    }
    this._http.post(this.myAppUrl + 'ModifyDataAdditionalApprovals', this.SingleSIApprovals).subscribe(
      data => {
        if (data.json() == "success") {
          this.GetAllAdditionalApprovals();
          this.GetAllLogInfo(this.selectModel.Id);
          if (this.ApprovalType == 1) {
            this.toastr.success('"Approved" status updated successfully', "");
          }
          else {
            this.toastr.success('"More Information Requested" status updated successfully', "");
          }
        }
        else {
          this.toastr.warning(data.json(), "");
        }
      },
      error => {
        console.log(JSON.stringify(error.json()));
      }
    )
  }

  showAddReviewComment: any;
  IsCommentEditMode: any;
  showCommentsModal() {
    this.selectModel.CommentType = '';
    this.selectModel.CommentDescription = '';
    this.selectModel.CommentStatus = undefined;
    this.showAddReviewComment = true;
    this.IsCommentEditMode = false;
  }
  fetchCommentType() {
    return (this.selectModel.CommentType == undefined || this.selectModel.CommentType == null) ? "" : this.selectModel.CommentType.CommentType;
  }
  fetchCommentStatus() {
    if (this.selectModel.CommentType != undefined && this.selectModel.CommentType != null && this.selectModel.CommentType != '') {
      var typ = this.selectModel.CommentType.CommentType;
      if (typ == 'Query') {
        this.selectModel.CommentStatus = '1';
      }
      else if (typ == 'Observation') {
        this.selectModel.CommentStatus = '3';
      }
      else if (typ == 'Query Response') {
        this.selectModel.CommentStatus = '4';
      }
    }
  }

  AddComments() {
    if (this.selectModel.CommentType == undefined || this.selectModel.CommentType == null || this.selectModel.CommentType == '') {
      this.toastr.warning('Please select "Comment Type"', "");
    }
    else if (this.selectModel.CommentDescription == undefined || this.selectModel.CommentDescription == null || this.selectModel.CommentDescription == '') {
      this.toastr.warning('Please enter "Comment Description"', "");
    }
    else {
      this.showAddReviewComment = false;
      this.modalService.dismissAll();
      var array = {
        RiskId: this.selectModel.Id,
        CommentTypeId: this.selectModel.CommentType.Id,
        CommentType: this.selectModel.CommentType.CommentType,
        CommentStatusId: this.selectModel.CommentStatus,
        CommentDescription: this.selectModel.CommentDescription,
        Reference: (this.SIApprovals.length).toString(),
      }
      this._http.post(this.myAppUrl + 'AddDataComments', array).subscribe(
        data => {
          if (data.json() == '' || data.json() == "success") {
            this.selectModel.CommentType = undefined;
            this.selectModel.CommentDescription = '';
            this.selectModel.CommentStatus = undefined;
            this.toastr.success('"Comment" added successfully', "");
            this.GetAllComments();
          }
          else {
            this.toastr.warning(data.json(), "");
          }
          this.IsAddDisabled = false;
        },
        error => {
          console.log(JSON.stringify(error.json()));
        }
      )
    }
  }

  GetCommentsConversionForId(AprId) {
    this.CommentsId = AprId;
    var list = this.PACommentsList.filter(o => o.Id == AprId)[0];
    if (list != null) {
      this.selectModel.CommentType = this.getdynamicobject(list.CommentTypeId, "CommentTypeList");
      this.selectModel.CommentDescription = list.CommentDescription;
      this.selectModel.CommentStatus = list.CommentStatusId;
      this.showAddReviewComment = true;
      this.IsCommentEditMode = true;
    }
  };

  CommentsId: any;
  UpdateComments() {
    if (this.selectModel.CommentType == undefined || this.selectModel.CommentType == null || this.selectModel.CommentType == '') {
      this.toastr.warning('Select Comment Type', "");
    }
    else if (this.selectModel.CommentDescription == undefined || this.selectModel.CommentDescription == null || this.selectModel.CommentDescription == '') {
      this.toastr.warning('Enter Comment Description', "");
    }
    else {
      this.showAddReviewComment = false;
      this.modalService.dismissAll();
      var prevObj = this.PrevPACommentsList.filter(o => o.Id == this.CommentsId)[0];
      var InternalStatus = '';
      if (prevObj != undefined && prevObj != null) {
        InternalStatus = prevObj.CommentStatus;
      }
      var array = {
        Id: this.CommentsId,
        RiskId: this.selectModel.Id,
        CommentTypeId: this.selectModel.CommentType.Id,
        CommentStatusId: this.selectModel.CommentStatus,
        CommentStatus: (this.selectModel.CommentStatus == '1' ? "Open" : this.selectModel.CommentStatus == '2' ? "Resolved" : this.selectModel.CommentStatus == '3' ? "Observation" :
          this.selectModel.CommentStatus == '4' ? "Responded" : ""),
        CommentDescription: this.selectModel.CommentDescription,
        InternalStatus: InternalStatus,
      }
      this._http.post(this.myAppUrl + 'UpdateDataComments', array).subscribe(
        data => {
          if (data.json() == '' || data.json() == "success") {
            this.selectModel.CommentType = undefined;
            this.selectModel.CommentDescription = '';
            this.selectModel.CommentStatus = undefined;
            this.toastr.success('"Comment" updated successfully', "");
            this.GetAllComments();
          }
          else {
            this.toastr.warning(data.json(), "");
          }
          this.IsAddDisabled = false;
        },
        error => {
          console.log(JSON.stringify(error.json()));
        }
      )
    }
  }
  GroupedCommentsList: any; ArchivedCommentsList: any;
  showArchivedComments(content) {
    var list = [];
    if (this.SIApprovals != null && this.SIApprovals.length > 0) {
      var lastApproval = this.SIApprovals[0].IsApproved;
      if (lastApproval == 'True') {
        list = this.CommentsAllList.filter(o => o.Reference != this.SIApprovals.length + 1);
      }
      else {
        list = this.CommentsAllList.filter(o => o.Reference != this.SIApprovals.length);
      }
    }
    this.GroupedCommentsList = this.unique(list, 'Reference');
    this.ArchivedCommentsList = list;
    this.openXl(content);
  }

  bindWhatData(list) {
    this.optionsWhatData = list.map(function (item) {
      return {
        item_text: item.WhatData,
        item_id: item.Id
      };
    });
  }
  assignWhatData(data) {
    this.selectModel.WhatDataselected = [];

    for (var index = 0; index < data.length; index++) {

      for (var k = 0; k < this.optionsWhatData.length; k++) {
        if (this.optionsWhatData[k].item_id == data[index].trim()) {
          this.selectModel.WhatDataselected.push(this.optionsWhatData[k]);
        }
      }
    }
  }
  bindWhereDataStored(list) {
    this.optionsWhereDataStored = list.map(function (item) {
      return {
        item_text: item.WhereDataStored,
        item_id: item.Id
      };
    });
  }
  assignWhereDataStored(data) {
    this.selectModel.WhereDataStoredselected = [];

    for (var index = 0; index < data.length; index++) {

      for (var k = 0; k < this.optionsWhereDataStored.length; k++) {
        if (this.optionsWhereDataStored[k].item_id == data[index].trim()) {
          this.selectModel.WhereDataStoredselected.push(this.optionsWhereDataStored[k]);
        }
      }
    }
  }
  bindImpactedBusinessProcesses(list) {
    this.optionsImpactedBusinessProcesses = list.map(function (item) {
      return {
        item_text: item.ImpactedBusinessProcesses,
        item_id: item.Id
      };
    });
  }
  assignImpactedBusinessProcesses(data) {
    this.selectModel.ImpactedBusinessProcessesselected = [];

    for (var index = 0; index < data.length; index++) {

      for (var k = 0; k < this.optionsImpactedBusinessProcesses.length; k++) {
        if (this.optionsImpactedBusinessProcesses[k].item_id == data[index].trim()) {
          this.selectModel.ImpactedBusinessProcessesselected.push(this.optionsImpactedBusinessProcesses[k]);
        }
      }
    }
  }
  bindHowObtainConsent(list) {
    this.optionsHowObtainConsent = list.map(function (item) {
      return {
        item_text: item.ConfirmLawfulBasis,
        item_id: item.Id
      };
    });
  }
  assignHowObtainConsent(data) {
    this.selectModel.HowObtainConsentselected = [];

    for (var index = 0; index < data.length; index++) {

      for (var k = 0; k < this.optionsHowObtainConsent.length; k++) {
        if (this.optionsHowObtainConsent[k].item_id == data[index].trim()) {
          this.selectModel.HowObtainConsentselected.push(this.optionsHowObtainConsent[k]);
        }
      }
    }
  }
  bindWhyCollectingData(list) {
    this.optionsWhyCollectingData = list.map(function (item) {
      return {
        item_text: item.WhyCollectingData,
        item_id: item.Id
      };
    });
  }
  assignWhyCollectingData(data) {
    this.selectModel.WhyCollectingDataselected = [];

    for (var index = 0; index < data.length; index++) {

      for (var k = 0; k < this.optionsWhyCollectingData.length; k++) {
        if (this.optionsWhyCollectingData[k].item_id == data[index].trim()) {
          this.selectModel.WhyCollectingDataselected.push(this.optionsWhyCollectingData[k]);
        }
      }
    }
  }
  bindWhatDataCollect(list) {
    this.optionsWhatDataCollect = list.map(function (item) {
      return {
        item_text: item.WhatDataCollect,
        item_id: item.Id
      };
    });
  }
  assignWhatDataCollect(data) {
    this.selectModel.WhatDataCollectselected = [];

    for (var index = 0; index < data.length; index++) {

      for (var k = 0; k < this.optionsWhatDataCollect.length; k++) {
        if (this.optionsWhatDataCollect[k].item_id == data[index].trim()) {
          this.selectModel.WhatDataCollectselected.push(this.optionsWhatDataCollect[k]);
        }
      }
    }
  }
  bindWhenObtainData(list) {
    this.optionsWhenObtainData = list.map(function (item) {
      return {
        item_text: item.WhenObtainData,
        item_id: item.Id
      };
    });
  }
  assignWhenObtainData(data) {
    this.selectModel.WhenObtainDataselected = [];

    for (var index = 0; index < data.length; index++) {

      for (var k = 0; k < this.optionsWhenObtainData.length; k++) {
        if (this.optionsWhenObtainData[k].item_id == data[index].trim()) {
          this.selectModel.WhenObtainDataselected.push(this.optionsWhenObtainData[k]);
        }
      }
    }
  }
  bindWhoAccessData(list) {
    this.optionsWhoAccessData = list.map(function (item) {
      return {
        item_text: item.WhoAccessData,
        item_id: item.Id
      };
    });
  }
  assignWhoAccessData(data) {
    this.selectModel.WhoAccessDataselected = [];

    for (var index = 0; index < data.length; index++) {

      for (var k = 0; k < this.optionsWhoAccessData.length; k++) {
        if (this.optionsWhoAccessData[k].item_id == data[index].trim()) {
          this.selectModel.WhoAccessDataselected.push(this.optionsWhoAccessData[k]);
        }
      }
    }
  }
  bindDataController(list) {
    this.optionsDataController = list.map(function (item) {
      return {
        item_text: item.UserName,
        item_id: item.Id
      };
    });
  }
  assignDataController(data) {
    this.selectModel.DataControllerselected = [];

    for (var index = 0; index < data.length; index++) {

      for (var k = 0; k < this.optionsDataController.length; k++) {
        if (this.optionsDataController[k].item_id == data[index].trim()) {
          this.selectModel.DataControllerselected.push(this.optionsDataController[k]);
        }
      }
    }
  }
  bindSupervisoryAuthority(list) {
    this.optionsSupervisoryAuthority = list.map(function (item) {
      return {
        item_text: item.UserName,
        item_id: item.Id
      };
    });
  }

  assignSupervisoryAuthority(data) {
    this.selectModel.SupervisoryAuthorityselected = [];

    for (var index = 0; index < data.length; index++) {

      for (var k = 0; k < this.optionsSupervisoryAuthority.length; k++) {
        if (this.optionsSupervisoryAuthority[k].item_id == data[index].trim()) {
          this.selectModel.SupervisoryAuthorityselected.push(this.optionsSupervisoryAuthority[k]);
        }
      }
    }
  }

  bindjointcontrollerdata(list) {
    this.optionsJointController = list.map(function (item) {
      return {
        item_text: item.UserName,
        item_id: item.Id
      };
    });
  }
  assignJointController(data) {
    this.selectModel.JointControllerselected = [];

    for (var index = 0; index < data.length; index++) {

      for (var k = 0; k < this.optionsJointController.length; k++) {
        if (this.optionsJointController[k].item_id == data[index].trim()) {
          this.selectModel.JointControllerselected.push(this.optionsJointController[k]);
        }
      }
    }
  }
  binddataprotectionofficerdata(list) {
    this.optionsDataProtectionOfficer = list.map(function (item) {
      return {
        item_text: item.UserName,
        item_id: item.Id
      };
    });
  }
  assignDataProtectionOfficer(data) {
    this.selectModel.DataProtectionOfficerselected = [];

    for (var index = 0; index < data.length; index++) {

      for (var k = 0; k < this.optionsDataProtectionOfficer.length; k++) {
        if (this.optionsDataProtectionOfficer[k].item_id == data[index].trim()) {
          this.selectModel.DataProtectionOfficerselected.push(this.optionsDataProtectionOfficer[k]);
        }
      }
    }
  }
  //showCommentsModal(content) {
  //  this.selectModel.AssetComments = "";
  //  this.openXl(content);
  //}
  showDataBreachModal(content) {
    this.selectModel.DBReference = '';
    this.selectModel.FilterByYear = '';
    this.selectModel.FilterByMonth = '';
    this.filteredDataBreachList = [];
    //this.selectModel.DBReference = this.selectModel.AssetID + (this.DataBreach.length + 1);
    //this.selectModel.DBReference = { Id: '', BreachSummary: '', DetailedDescription: '', IStepsTaken: '', ActionsRequired: '', ActionsCompletedDesc: '', };
    this.openXl(content)
  }
  CommentsData: any;
  getDateTime() {
    var now = new Date();
    var year = now.getFullYear();
    var month = (now.getMonth() + 1).toString();
    var day = (now.getDate()).toString();
    var hour = (now.getHours()).toString();
    var minute = (now.getMinutes()).toString();
    var second = (now.getSeconds()).toString();
    if (month.toString().length == 1) {
      var month = '0' + month;
    }
    if (day.toString().length == 1) {
      var day = '0' + day;
    }
    if (hour.toString().length == 1) {
      var hour = '0' + hour;
    }
    if (minute.toString().length == 1) {
      var minute = '0' + minute;
    }
    if (second.toString().length == 1) {
      var second = '0' + second;
    }
    var dateTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
    return dateTime;
  }
  filteredDataBreachList: any;
  filterDataBreach() {
    if (!Boolean(this.selectModel.FilterByYear)) {
      this.toastr.warning('Please select "Year"', "");
    }
    else if (!Boolean(this.selectModel.FilterByMonth)) {
      this.toastr.warning('Please select "Month"', "");
    }
    else {
      this.filteredDataBreachList = [];
      console.log(this.DataBreachList)
      if (Boolean(this.selectModel.FilterByYear) && Boolean(this.selectModel.FilterByMonth)) {
        this.filteredDataBreachList = this.DataBreachList.filter(o => o.Year == this.selectModel.FilterByYear.Year && o.Month == this.selectModel.FilterByMonth.Month)
      }
      else if (Boolean(this.selectModel.FilterByYear)) {
        this.filteredDataBreachList = this.DataBreachList.filter(o => o.Year == this.selectModel.FilterByYear.Year)
      }
      else if (Boolean(this.selectModel.FilterByMonth)) {
        this.filteredDataBreachList = this.DataBreachList.filter(o => o.Month == this.selectModel.FilterByMonth.Month)
      }
      this.filteredDataBreachList = this.filteredDataBreachList.filter(o => o.IsSelected != true);
      //for (var l of this.filteredDataBreachList) {
      //  l.IsSelected = false;
      //}
    }
  }
  MonthsList: any;
  loadMonthsByYear() {
    this.MonthsList = [];
    this.selectModel.FilterByMonth = '';
    if (Boolean(this.selectModel.FilterByYear)) {
      this.MonthsList = this.DataBreachList.filter(o => o.Year == this.selectModel.FilterByYear.Year);
    }
  }
  DataBreach: any;
  AddDataBreach() {
    var list = this.filteredDataBreachList.filter(o => o.IsSelected);
    if (list == undefined || list == null || list.length <= 0) {
      this.toastr.warning('Please select "Data Breach"', "")
    }
    else {
      for (let l of list) {
        this.DataBreach.push(l);
        l.IsSelected = false;
      }
      //this.DataBreach.push(this.selectModel.DBReference);
      this.modalService.dismissAll();
      this.toastr.success('"Data Breach" added successfully', "");
    }
  }
  confirmModel() {
    const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: 'confirm-modal' });
    modalRef.componentInstance.name = 'Confirm';
  }
  cancel() {
    this.editMode = false;
    this.showAddwindow = false;
    this.modalService.dismissAll();
    this.getallgriddata();
  }
  openCM(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: 'confirm-modal' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  open(content, className) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: className }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openXl(content) {
    this.modalService.open(content, { size: 'xl' });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  boolToNumeric(obj) {
    if (obj) {
      return '1';
    }
    else {
      return '0';
    }
  }
  stringtoBoolean(obj) {
    if (obj == '1') {
      return true;
    }
    else {
      return false;
    }
  }
  updateColumnAccess(obj, typ) {
    if (typ == 1) {
      var array = {
        UniqDataReference: obj.All ? '1' : '0',
        WhoseData: obj.All ? '1' : '0',
        WhatDataCollect: obj.All ? '1' : '0',
        WhatData: obj.All ? '1' : '0',
        WhyCollectingData: obj.All ? '1' : '0',
        ApprovalStatus: obj.All ? '1' : '0',
      }
      this._http.post(this.myAppUrl + 'ModifyDataSubjectsColumnDisplay', array).subscribe(
        data => {
          if (data.json() == '' || data.json() == "success") {
            this.getallcolumndisplay();
          }
          else {
            this.toastr.warning(data.toString(), "");
          }
          this.IsAddDisabled = false;
        },
        error => {
          console.log(JSON.stringify(error.json()));
        }
      )
    }
    else {
      var arrayObj = {
        UniqDataReference: this.boolToNumeric(obj.UniqDataReference),
        WhoseData: this.boolToNumeric(obj.WhoseData),
        WhatDataCollect: this.boolToNumeric(obj.WhatDataCollect),
        WhatData: this.boolToNumeric(obj.WhatData),
        WhyCollectingData: this.boolToNumeric(obj.WhyCollectingData),
        ApprovalStatus: this.boolToNumeric(obj.ApprovalStatus),
      }
      this._http.post(this.myAppUrl + 'ModifyDataSubjectsColumnDisplay', arrayObj).subscribe(
        data => {
          if (data.json() == '' || data.json() == "success") {
            this.showHideColsHeader.All = false;
            if (Object.keys(arrayObj).every((k) => this.stringtoBoolean(arrayObj[k]))) {
              this.showHideColsHeader.All = true;
            }
          }
          else {
            this.toastr.warning(data.toString(), "");
          }
          this.IsAddDisabled = false;
        },
        error => {
          console.log(JSON.stringify(error.json()));
        }
      )
    }

  }
  userId: any;
  GetRightsList() {
    this.appService.getloggedusername()
      .subscribe((data) => {
        var userId = data;
        this.userId = data;
      }, error => console.log(error))
  };
}
